import { CSSProperties } from 'react';
import {
	InterludesData,
	WebsiteConfigsData
} from '../../redux/constants/dashboard_objects';
import Styles from './interlude_text.module.scss';
import MenuItems from '../../helper/MenuItems';
import ShareIcons from '../../helper/Share';
import { useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { addToFavorites, authSelector, deleteFavorites } from '../../redux/reducer/auth.reducer';
import VideoOverlay from '../video_player/video_player';
import { urlSearchSelector } from '../../redux/reducer/url_search.reducer';
import { useSearchParams } from 'react-router-dom';
import GlobalAudio from '../global_audio/GlobalAudio';

interface customCSSProperties extends CSSProperties {
	'--background': string | undefined;
}
const InterludeText = ({
	curPage,
	data,
	config,
}: {
	curPage: string;
	data: InterludesData;
	config?: WebsiteConfigsData;
}) => {
	const dispatch = useAppDispatch();
	const { user, loading } = useSelector(authSelector);

	const { page, point } = useSelector(urlSearchSelector);
	const [, setSearchParams] = useSearchParams();

	const addToPlaylist = () => {
		if (user == null || loading) return;
		// if user is not logged in, redirect to login page
		const add = added();
		if (add) {
			dispatch(deleteFavorites({ id: add.id }));
		} else {
			dispatch(
				addToFavorites({
					user_id: user.id,
					playlist_id: user.id + '_' + page,
					playlist: data
				})
			);
		}
	};

	const added = () => {
		return user?.playlists?.find((playlist) => playlist.unique === user.id + '_' + page);
	};

	const closeVideo = () => {
		setSearchParams({ page: curPage });
	};

	const openVideo = () => {
		setSearchParams({ page: curPage, point: 'video' });
	};
	const customStyles: customCSSProperties = {
		'--background': `url(${data?.background_image?.data?.attributes?.url})`
	};

	return (
		<div className={Styles.main_body} style={customStyles}>
			<GlobalAudio />

			<div style={{ zIndex: '100', position: 'relative' }}>
				{page == curPage && point == 'video' ? (
					<VideoOverlay
						video={data.map_video?.data?.attributes}
						onClose={closeVideo}
						add={addToPlaylist}
						loading={loading}
						added={added() != null}
					/>
				) : (
					''
				)}
			</div>
			<img
				className={Styles.main_body_background}
				src={(process.env.REACT_APP_BASE_URL ?? '') + data?.grains_image?.data?.attributes?.url}
				alt={data?.grains_image?.data?.attributes?.alternativeText}
			/>
			<div className={Styles.custom_container}>
				<div className={Styles.video_container}>
					<img
						className={Styles.video_thumbnail}
						src={
							(process.env.REACT_APP_BASE_URL ?? '') +
							data?.featured_image?.data?.attributes?.url
						}
						alt={data?.featured_image?.data?.attributes?.alternativeText}
					/>
					<h1 className={Styles.title_text}>INTERLUDE</h1>
					<img
						onClick={openVideo}
						className={Styles.video_playbtn}
						src="./images/play_btn.png"
						alt=""
					/>
				</div>
				<div className={Styles.form}>
					<input type="text" placeholder="Enter your memories" />
					<img className={Styles.input_logo} src="./images/input_symbol.png" alt="" />
				</div>
				<div className={Styles.menu_bar}>
					<img
						src={
							(process.env.REACT_APP_BASE_URL ?? '') +
							config?.interlude_logo.data.attributes?.url
						}
						alt=""
						className={Styles.logo}
						onClick={() =>
							document.getElementById('homepage')?.scrollIntoView({ behavior: 'smooth' })
						}
					/>
					<div className={Styles.menu_items}>
						<MenuItems />
					</div>
				</div>
				<div className={Styles.random_image}>
					<img
						src={
							(process.env.REACT_APP_BASE_URL ?? '') +
							data?.mark_image?.data?.attributes?.url
						}
						alt={data?.mark_image?.data?.attributes?.alternativeText}
					/>
				</div>
				<div className={Styles.social_media_icons}>
					{/* TODO add share data */}
					<ShareIcons
						text={`The city cultivates its own pauses; here within The Mapmaker from Baghdad, ${window.location.href}`}
					/>
				</div>
			</div>
		</div>
	);
};

export default InterludeText;
