export const ButtonConstants = {
    GO_TO_SIGNUP: 'go to signup',
    ENTER_ARCHIVE: 'enter the archive',
    SUBMIT: 'submit',
    GO_TO_LOGIN: 'go to login',
    LOGOUT: 'logout',
};

export class ErrorMessage {
    static EMAIL_NOT_CONFIRMED = 'Your account email is not confirmed';
    static EMAIL_ALREADY_EXIST = 'Email ID already taken';
    static INCORRECT_EMAIL_OR_PASSWORD = 'Incorrect email or password';
    static SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later.';
    static INVALID_PASSWORD = 'Password must be more then 8 characters';
    static INVALID_CONFIRM_PASSWORD = 'Passwords do not match!';
}

export class SuccessMessage {
    static RESET_EMAIL_SEND = 'Password reset email sent successfully!';
}