import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/home/Home';

function Router() {
	// routes
	const browserRouter = createBrowserRouter([
		{
			path: '/',
			element: <Home />
		}
	]);

	return (
		<>
			<RouterProvider router={browserRouter} />
		</>
	);
}

export default Router;
