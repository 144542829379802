import { MapData } from '../../redux/constants/dashboard_objects';
import Style from '../comman_styles/points.module.scss';
import PointsClicked from '../tooltip/PointsClicked';
import CustomToolTip from '../tooltip/ToolTip';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { urlSearchSelector } from '../../redux/reducer/url_search.reducer';
import { effectAction } from '../../redux/reducer/effect.reducer';
import { useAppDispatch } from '../../store';
const Points = ({ data }: { data: { attributes: MapData }[] }) => {
	const curPage = 'page_18';
	const { page, point } = useSelector(urlSearchSelector);
	const [, setSearchParams] = useSearchParams();
	const selected = page == curPage ? point : null;
	const dispatch = useAppDispatch();

	const onClick = (index: number) => {
		dispatch(effectAction.onPointOpenEffect());
		setSearchParams({ page: curPage, point: index.toString() });
	};

	const onClose = () => {
		dispatch(effectAction.onPointCloseEffect());
		setSearchParams({ page: curPage });
	};

	return (
		<div style={{ position: 'relative' }}>
			<svg
				className={Style.map_svg}
				width="1280"
				height="800"
				viewBox="0 0 1280 800"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<CustomToolTip title={data[0]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(0)}
						d="M291 27.5V7.5H258.5V27.5H291Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[1]?.attributes.map_hover_text} placement="right">
					<path
						className={Style.select}
						onClick={() => onClick(1)}
						d="M103.5 319V299H71V319H103.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[2]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(2)}
						d="M214.5 609V589H182V609H214.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[3]?.attributes.map_hover_text} placement="right">
					<path
						className={Style.select}
						onClick={() => onClick(3)}
						d="M68.5 756V736H36V756H68.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[4]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(4)}
						d="M1166.5 464V444H1134V464H1166.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[5]?.attributes.map_hover_text} placement="left">
					<path
						className={Style.select}
						onClick={() => onClick(5)}
						d="M1276.5 249V228H1244V249H1276.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[6]?.attributes.map_hover_text} placement="left">
					<path
						className={Style.select}
						onClick={() => onClick(6)}
						d="M1240.5 755V734H1208V755H1240.5Z"
						stroke="black"
					/>
				</CustomToolTip>
			</svg>
			{selected != null && (
				<PointsClicked
					data={data[selected].attributes}
					id={'map1-' + selected}
					onClose={onClose}
				/>
			)}
		</div>
	);
};

export default Points;
