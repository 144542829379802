import { FormEvent, useState } from "react";
import { authAction, AuthPage, resetPasswordUser } from "../../redux/reducer/auth.reducer";
import { ButtonConstants, ErrorMessage } from "./common/Constants";
import UserButtons from "./common/user_buttons/UserButtons";
import { PasswordInput, UserError, UserMessage } from "./common/user_input/UserInput";
import { useAppDispatch } from "../../store";
import { validatePassword } from "./Validation";

const ResetPasswordComponent = ({ code }: { code: string }) => {

    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const dispatch = useAppDispatch();

    function resetError() {
        setError(null);
        setPasswordError(false);
        setConfirmPasswordError(false);
        setMessage(null);
    }

    async function submit(e: FormEvent) {
        e.preventDefault();
        if (loading) return;
        resetError();

        if(!validatePassword(password)){
            setError(ErrorMessage.INVALID_PASSWORD);
            setPasswordError(true);
            return;
        }
        if(password != confirmPassword){
            setError(ErrorMessage.INVALID_CONFIRM_PASSWORD);
            setConfirmPasswordError(true);
            return;
        }
        setLoading(true);
        const [error] = await resetPasswordUser(code, password);
        if (error) {
            setError(error.toString());
        } else {
            setMessage("Password reset successfully!")
        }
        setLoading(false);
    }

    function onGoToLogin() {
        if (loading) return;
        dispatch(authAction.update_state(AuthPage.LOGIN));
    }

    return (
        <form onSubmit={submit}>
            <PasswordInput inputValue={password} setInputValue={setPassword} error={passwordError} title="password"/>
            <PasswordInput inputValue={confirmPassword} setInputValue={setConfirmPassword} error={confirmPasswordError} title="confirm password"/>
            <UserError error={error} />
            <UserMessage message={message} />
            <UserButtons
                leftButtonData={{ text: ButtonConstants.GO_TO_LOGIN, onClick: onGoToLogin }}
                rightButtonData={{ text: ButtonConstants.SUBMIT, onClick: () => { }, type: 'submit' }} />
        </form>
    )
}
export default ResetPasswordComponent;