// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginMessage_msg_outer__GIJx7 {
  font-family: FSB;
}

.LoginMessage_title__H0FkD {
  color: white;
  font-size: 10px;
  font-family: FSB;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.LoginMessage_message__p6QlI {
  font-family: FSB;
  color: white;
  margin-top: 20px;
  line-height: 1.3;
  font-size: 10px;
  margin-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/componenets/user_page/common/login_message/LoginMessage.module.scss"],"names":[],"mappings":"AACA;EACI,gBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;AAAJ;;AAGA;EACC,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACG,eAAA;EACH,mBAAA;AAAD","sourcesContent":["\n.msg_outer{\n    font-family: FSB;\n}\n\n.title {\n    color: white;\n    font-size: 10px;\n    font-family: FSB;\n    font-weight: 700;\n    margin-bottom: 10px;\n    text-transform: uppercase;\n}\n\n.message {\n\tfont-family: FSB;\n\tcolor: white;\n\tmargin-top: 20px;\n\tline-height: 1.3;\n    font-size: 10px;\n\tmargin-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msg_outer": `LoginMessage_msg_outer__GIJx7`,
	"title": `LoginMessage_title__H0FkD`,
	"message": `LoginMessage_message__p6QlI`
};
export default ___CSS_LOADER_EXPORT___;
