// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GlobalAudio_globalAudioBtn__v\\+Fmt {
  cursor: pointer;
  height: 20px;
  width: 60px;
  z-index: 30;
  position: absolute;
  max-width: 60px;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10.7%;
  top: 4%;
}

.GlobalAudio_globalMute__G7CEI {
  width: 58%;
  height: 2.5px;
}

.GlobalAudio_globalUnmute__mtJSF {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/componenets/global_audio/GlobalAudio.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,OAAA;AACD;;AAEA;EACC,UAAA;EACA,aAAA;AACD;;AAEA;EACC,WAAA;AACD","sourcesContent":[".globalAudioBtn {\n\tcursor: pointer;\n\theight: 20px;\n\twidth: 60px;\n\tz-index: 30;\n\tposition: absolute;\n\tmax-width: 60px;\n\tmax-height: 60px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tposition: absolute;\n\tright: 10.7%;\n\ttop: 4%;\n}\n\n.globalMute {\n\twidth: 58%;\n\theight: 2.5px;\n}\n\n.globalUnmute {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"globalAudioBtn": `GlobalAudio_globalAudioBtn__v+Fmt`,
	"globalMute": `GlobalAudio_globalMute__G7CEI`,
	"globalUnmute": `GlobalAudio_globalUnmute__mtJSF`
};
export default ___CSS_LOADER_EXPORT___;
