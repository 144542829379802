import { useSelector } from 'react-redux';
import { addToFavorites, authSelector, deleteFavorites } from '../../redux/reducer/auth.reducer';
import { useAppDispatch } from '../../store';
import AudioPlayer from '../audio_player/audioPlayer';
import Carousel from '../carousel/Carousel';
import VideoOverlay from '../video_player/video_player';
import { MenuItemsTypes } from '../../helper/MenuItems';
import { MapGallery, MapVideo, MapAudio } from '../../redux/constants/dashboard_objects';

export interface PointsClickedData {
	point_type?: 'Gallery' | 'Video' | 'Audio' | null;
	map_gallery?: { data: { attributes: MapGallery } };
	map_video?: { data: { attributes: MapVideo } };
	map_audio?: { data: { attributes: MapAudio } };
}

export default function PointsClicked({
	data,
	id,
	onClose,
}: {
	data: PointsClickedData;
	id: string;
	onClose: () => void;
}) {
	const dispatch = useAppDispatch();
	const { user, loading } = useSelector(authSelector);

	const addToPlaylist = () => {
		if (user == null || loading) {
			document.getElementById(MenuItemsTypes.USER)?.scrollIntoView({ behavior: 'smooth' });
			return;
		}

		const add = added();
		if (add) {
			dispatch(deleteFavorites({ id: add.id }));
		} else {
			dispatch(
				addToFavorites({ user_id: user.id, playlist_id: user.id + '_' + id, playlist: data })
			);
		}
	};

	const added = () => {
		return user?.playlists?.find(
			(playlist) => playlist.unique === user.id + '_' + id || playlist.unique === id
		);
	};

	if (data.point_type === 'Gallery' && data.map_gallery) {
		return (
			<div style={{ zIndex: '100', position: 'relative' }}>
				<Carousel gallery={data.map_gallery.data.attributes} onClose={onClose} />
			</div>
		);
	}

	if (data.point_type === 'Audio' && data.map_audio) {
		return (
			<div style={{ zIndex: '100', position: 'relative' }}>
				<AudioPlayer
					currentSong={data.map_audio.data.attributes}
					onClose={onClose}
					add={addToPlaylist}
					loading={loading}
					added={added() != null}
				/>
			</div>
		);
	}

	if (data.map_video) {
		return (
			<div style={{ zIndex: '100', position: 'relative' }}>
				<VideoOverlay
					video={data.map_video.data.attributes}
					onClose={onClose}
					add={addToPlaylist}
					loading={loading}
					added={added() != null}
				/>
			</div>
		);
	}

	return <></>;
}
