import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

type initialStateType = {
	onPointStatusChange: boolean | null;
};

const initialState: initialStateType = { onPointStatusChange: null };

const effectSlice = createSlice({
	name: 'effect',
	initialState: initialState,
	reducers: {
		onPointOpenEffect: (state) => {
			return { ...state, onPointStatusChange: true };
		},
		onPointCloseEffect: (state) => {
			return { ...state, onPointStatusChange: false };
		}
	}
});

export const effectAction = effectSlice.actions;
export const effectSelector = (state: RootState) => state.effectReducer;
export const effectReducer = effectSlice.reducer;
