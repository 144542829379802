const API = {
	SIGNIN: process.env.REACT_APP_BASE_URL + '/api/auth/local?populate=*',
	SIGNUP: process.env.REACT_APP_BASE_URL + '/api/auth/local/register',
	SIGNOUT: process.env.REACT_APP_BASE_URL + '/api/auth/signOut',
	VERIFY_TOKEN: process.env.REACT_APP_BASE_URL + '/api/users/me?populate=*',
	ADD_TO_PLAYLIST: process.env.REACT_APP_BASE_URL + '/api/playlists',
	DASHBOARD:
		process.env.REACT_APP_BASE_URL +
		'/api/single-api-call?populate[episodes][populate][map_video][populate]=*&populate[episodes][populate][featured_image][populate]=*&populate[episodes][populate][background_image][populate]=*&populate[episodes][populate][mark_image][populate]=*&populate[episodes][populate][random_image][populate]=*&populate[prologues][populate]=*&populate[cycle_cinemas][populate]=*&populate[interludes][populate][map_video][populate]=*&populate[interludes][populate][featured_image][populate]=*&populate[interludes][populate][background_image][populate]=*&populate[interludes][populate][mark_image][populate]=*&populate[interludes][populate][random_image][populate]=*&populate[interludes][populate][glitch_image][populate]=*&populate[epilogues][populate]=*&populate[social_media_links][populate]=*&populate[website_configs][populate]=*&populate[map_1s][populate][map_gallery][populate]=*&populate[map_1s][populate][map_audio][populate]=*&populate[map_1s][populate][map_video][populate]=*&populate[map_2s][populate][map_gallery][populate]=*&populate[map_2s][populate][map_audio][populate]=*&populate[map_2s][populate][map_video][populate]=*&populate[map_3s][populate][map_gallery][populate]=*&populate[map_3s][populate][map_audio][populate]=*&populate[map_3s][populate][map_video][populate]=*&populate[map_4s][populate][map_gallery][populate]=*&populate[map_4s][populate][map_audio][populate]=*&populate[map_4s][populate][map_video][populate]=*&populate[map_5s][populate][map_gallery][populate]=*&populate[map_5s][populate][map_audio][populate]=*&populate[map_5s][populate][map_video][populate]=*&populate[map_6s][populate][map_gallery][populate]=*&populate[map_6s][populate][map_audio][populate]=*&populate[map_6s][populate][map_video][populate]=*&populate[map_images][populate]=*',
	ARCHIVE: process.env.REACT_APP_BASE_URL + '/api/archives',
	FORGOT: process.env.REACT_APP_BASE_URL + '/api/auth/forgot-password',
	RESET: process.env.REACT_APP_BASE_URL + '/api/auth/reset-password'
};

export default API;
