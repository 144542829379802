// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GridAnimation_hidden__IiBLO {
  opacity: 0.5;
  transition: opacity 0.5s ease;
  position: relative;
}

.GridAnimation_point__KEVJi {
  position: absolute;
  background-color: black;
  width: 0px;
  height: 0px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  display: flex;
}

.GridAnimation_absolute__\\+uVVR {
  position: absolute;
}

.GridAnimation_gridItem__PLlld {
  scale: var(--scale, 1);
  transform-origin: center left;
  overflow: hidden;
  scroll-snap-stop: always;
  -webkit-scroll-snap-align: center;
  scroll-snap-align: center;
  width: 1280px;
  height: 800px;
}`, "",{"version":3,"sources":["webpack://./src/componenets/comman_styles/GridAnimation.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,6BAAA;EACA,kBAAA;AACD;;AAEA;EACC,kBAAA;EACA,uBAAA;EACA,UAAA;EACA,WAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,SAAA;EACA,aAAA;AACD;;AAEA;EACC,kBAAA;AACD;;AAEA;EAEC,sBAAA;EACA,6BAAA;EACA,gBAAA;EACA,wBAAA;EACA,iCAAA;EACA,yBAAA;EACA,aAAA;EACA,aAAA;AAAD","sourcesContent":[".hidden {\n\topacity: 0.5;\n\ttransition: opacity 0.5s ease;\n\tposition: relative;\n}\n\n.point {\n\tposition: absolute;\n\tbackground-color: black;\n\twidth: 0px;\n\theight: 0px;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tmargin: auto;\n\tbottom: 0;\n\tdisplay: flex;\n}\n\n.absolute {\n\tposition: absolute;\n}\n\n.gridItem {\n\t// scale comming from parent\n\tscale: var(--scale, 1);\n\ttransform-origin: center left;\n\toverflow: hidden;\n\tscroll-snap-stop: always;\n\t-webkit-scroll-snap-align: center;\n\tscroll-snap-align: center;\n\twidth: 1280px;\n\theight: 800px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": `GridAnimation_hidden__IiBLO`,
	"point": `GridAnimation_point__KEVJi`,
	"absolute": `GridAnimation_absolute__+uVVR`,
	"gridItem": `GridAnimation_gridItem__PLlld`
};
export default ___CSS_LOADER_EXPORT___;
