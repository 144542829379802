import './App.scss';
import styles from './App.module.scss';
import Router from './Router';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './index.css';

function App() {
	return (
		<div className={styles.apps}>
			<ToastContainer />
			<Router />
		</div>
	);
}

export default App;
