import { CSSProperties, useEffect, useState } from 'react';
import MenuItems from '../../helper/MenuItems';
import ShareIcons from '../../helper/Share';
import { InterludesData, WebsiteConfigsData } from '../../redux/constants/dashboard_objects';
import Styles from './interlude_2.module.scss';
import { useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { addToFavorites, authSelector, deleteFavorites } from '../../redux/reducer/auth.reducer';
import VideoOverlay from '../video_player/video_player';
import { urlSearchSelector } from '../../redux/reducer/url_search.reducer';
import { useSearchParams } from 'react-router-dom';
import { MenuItemsTypes } from '../../helper/MenuItems';
import GlobalAudio from '../global_audio/GlobalAudio';
import MemoryRequest from '../../models/memory_request';
import ContentService from '../../redux/service/content.service';
import SubmitBtn from './Insert.png';
import loadingBar from './loadingBar.gif';

interface customCSSProperties extends CSSProperties {
	'--logo': string | undefined;
}

const Interlude2 = ({
	curPage,
	data,
	config
}: {
	curPage: string;
	data: InterludesData;
	config: WebsiteConfigsData;
}) => {
	const customStyles: customCSSProperties = {
		'--logo': `url(${(process.env.REACT_APP_BASE_URL ?? '') + data?.background_image?.data?.attributes?.url})`
	};
	const dispatch = useAppDispatch();
	const { user, loading } = useSelector(authSelector);

	const { page, point } = useSelector(urlSearchSelector);
	const [, setSearchParams] = useSearchParams();

	const addToPlaylist = () => {
		if (user == null || loading) {
			document.getElementById(MenuItemsTypes.USER)?.scrollIntoView({ behavior: 'smooth' });
			return;
		}
		// if user is not logged in, redirect to login page
		const add = added();
		if (add) {
			dispatch(deleteFavorites({ id: add.id }));
		} else {
			dispatch(
				addToFavorites({
					user_id: user.id,
					playlist_id: user.id + '_' + page,
					playlist: data
				})
			);
		}
	};

	const added = () => {
		return user?.playlists?.find((playlist) => playlist.unique === user.id + '_' + page);
	};

	const closeVideo = () => {
		setSearchParams({ page: curPage });
	};

	const openVideo = () => {
		setSearchParams({ page: curPage, point: 'video' });
	};

	const [memory, setMemory] = useState('');
	const [sendingData, setSendingData] = useState(false);
	const [dataSent, setDataSent] = useState(false);
	// function to name text
	const saveMemory = async () => {
		if (memory.trim() == '') return;
		setSendingData(true);

		try {
			const memoryRequest: MemoryRequest = {
				memory: 'none',
				username: 'anonymous',
				mail_id: 'anonymous',
				message: 'none'
			};

			if (user) {
				memoryRequest.mail_id = user?.email ?? '';
				memoryRequest.username = user?.username ?? '';
			}

			if (data.horizontal_type === 'Horizontal Yellow') {
				memoryRequest.memory = memory;
				memoryRequest.message = 'none';
			} else {
				memoryRequest.memory = 'none';
				memoryRequest.message = memory;
			}

			await ContentService.postMemory(memoryRequest);
			setMemory('');
			setDataSent(true);
		} catch (error) {
			console.log('error', error);
		} finally {
			setSendingData(false);
		}
	};

	useEffect(() => {
		if (page != curPage && memory.length > 0) {
			setMemory('');
		}
	}, [page]);

	return (
		<div className={Styles.main_body} style={customStyles}>
			<GlobalAudio />
			<div style={{ zIndex: '100', position: 'relative' }}>
				{page == curPage && point == 'video' ? (
					<VideoOverlay
						noBg={true}
						disableDetails={true}
						video={data.map_video?.data?.attributes}
						onClose={closeVideo}
						add={addToPlaylist}
						loading={loading}
						added={added() != null}
					/>
				) : (
					<></>
				)}
			</div>
			<img
				className={Styles.main_body_background}
				src={
					(process.env.REACT_APP_BASE_URL ?? '') +
					data?.background_image?.data?.attributes?.url
				}
				alt={data?.background_image?.data?.attributes?.alternativeText}
			/>
			<div className={Styles.custom_container}>
				<div className={Styles.video_container}>
					<img
						className={Styles.video_thumbnail}
						src={
							(process.env.REACT_APP_BASE_URL ?? '') +
							data?.featured_image?.data?.attributes?.url
						}
						alt={data?.featured_image?.data?.attributes?.alternativeText}
					/>
					{data?.glitch_image != null && (
						<img
							className={
								Styles.glitch_image + ' ' + (sendingData || dataSent ? Styles.show : '')
							}
							src={
								(process.env.REACT_APP_BASE_URL ?? '') +
								data?.glitch_image?.data?.attributes?.url
							}
							alt={data?.glitch_image?.data?.attributes?.alternativeText}
						/>
					)}
					<img
						onClick={openVideo}
						className={Styles.video_playbtn}
						src="./images/play_btn.png"
						alt=""
					/>
				</div>
				<div className={Styles.form + ' ' + (dataSent ? Styles.dismiss_form : '')}>
					{data?.horizontal_type === 'Horizontal Yellow' ? (
						<input
							className={Styles.dismiss_input}
							type="text"
							data-has-input={memory.length > 0}
							disabled={dataSent}
							placeholder="The city is replete with murmurs. Can you tell us what it whispers in your ear?"
							value={memory}
							onChange={(e) => setMemory(e.target.value)}
						/>
					) : (
						<input
							className={Styles.pink_template}
							disabled={dataSent}
							data-has-input={memory.length > 0}
							type="text"
							placeholder="HOW WILL THE CITY REMEMBER YOU? WHAT RELIC WILL YOU LEAVE BEHIND?"
							value={memory}
							onChange={(e) => setMemory(e.target.value)}
						/>
					)}
					{/* <img className={Styles.input_logo} src="./images/input_symbol.png" alt="" /> */}
					<div className={Styles.input_circle} onClick={saveMemory}>
						{sendingData ? <img src={loadingBar}></img> : <img src={SubmitBtn} />}
					</div>
				</div>
				<div className={Styles.menu_bar}>
					<img
						src={
							(process.env.REACT_APP_BASE_URL ?? '') +
							config.interlude_logo.data.attributes?.url
						}
						alt={config.interlude_logo.data.attributes?.alternativeText}
						className={Styles.logo}
						onClick={() =>
							document.getElementById('homepage')?.scrollIntoView({ behavior: 'smooth' })
						}
					/>
					<div className={Styles.menu_items}>
						<MenuItems />
					</div>
				</div>
				<div className={Styles.random_image}>
					<img
						src={
							(process.env.REACT_APP_BASE_URL ?? '') +
							data?.mark_image?.data?.attributes?.url
						}
						alt={data?.mark_image?.data?.attributes?.alternativeText}
					/>
				</div>
				<div className={Styles.social_media_icons}>
					<ShareIcons
						text={`An interlude, a moment of pause, a site of contemplation from The Mapmaker from Baghdad here, ${window.location.href}`}
					/>
				</div>
			</div>
		</div>
	);
};

export default Interlude2;
