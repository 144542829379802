import { useEffect, useMemo } from 'react';
import Styles from './LoadingScreen.module.scss';
import { useAppDispatch } from '../../store';
import { dashboardDataAction } from '../../redux/reducer/dashboard_data.reducer';
import loadingscreen from './loadingscreen.gif';
import { audioAction } from '../../redux/reducer/audio.reducer';

const gif_time = (7 * 1000) - 100;
function LoadingScreen() {

  const dispatch = useAppDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(dashboardDataAction.loadingAnimationCompleted());
    }, gif_time)

    return () => clearTimeout(timer)
  }, [])

  const random = useMemo(() => {
    const random = Math.floor(Math.random() * 1000);
    return random
  }, [])

  function onFirstInteraction() {
    dispatch(audioAction.onFirstInteraction())
  }

  return (
    <img onClick={onFirstInteraction} className={Styles.loading} src={loadingscreen + "?v=" + random} />
  )
}

export default LoadingScreen;