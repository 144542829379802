import { useSearchParams } from 'react-router-dom';
import Style from '../comman_styles/points.module.scss';
import CustomToolTip from '../tooltip/ToolTip';
import PointsClicked from '../tooltip/PointsClicked';
import { MapData } from '../../redux/constants/dashboard_objects';
import { useSelector } from 'react-redux';
import { urlSearchSelector } from '../../redux/reducer/url_search.reducer';
import { useAppDispatch } from '../../store';
import { effectAction } from '../../redux/reducer/effect.reducer';

const Points = ({ data }: { data: { attributes: MapData }[] }) => {
	const curPage = 'page_20';
	const { page, point } = useSelector(urlSearchSelector);
	const [, setSearchParams] = useSearchParams();
	const selected = page == curPage ? point : null;
	const dispatch = useAppDispatch();

	const onClick = (index: number) => {
		dispatch(effectAction.onPointOpenEffect());
		setSearchParams({ page: curPage, point: index.toString() });
	};

	const onClose = () => {
		dispatch(effectAction.onPointCloseEffect());
		setSearchParams({ page: curPage });
	};

	return (
		<div style={{ position: 'relative' }}>
			<svg
				className={Style.map_svg}
				width="1280"
				height="800"
				viewBox="0 0 1280 800"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<CustomToolTip title={data[0]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(0)}
						d="M124.5 107L42.5 175L64 276.5L191 175L255 150L259.5 107H124.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[1]?.attributes.map_hover_text} placement="right">
					<path
						className={Style.select}
						onClick={() => onClick(1)}
						d="M92 467.5V397L195 393L205.5 425L92 467.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[2]?.attributes.map_hover_text} placement="right">
					<path
						className={Style.select}
						onClick={() => onClick(2)}
						d="M82.5 643L129 533.5L345.5 586.5L226.5 678L82.5 643Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[3]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(3)}
						d="M740.5 204L790 141L816.5 204L785.5 260L740.5 204Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[4]?.attributes.map_hover_text} placement="left">
					<path
						className={Style.select}
						onClick={() => onClick(4)}
						d="M1052.5 509L985 411.5L1065.79 303.5H1156.5V509H1052.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[5]?.attributes.map_hover_text} placement="left">
					<path
						className={Style.select}
						onClick={() => onClick(5)}
						d="M1224.5 73.5H1118V168.5L1252.5 135L1224.5 73.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[6]?.attributes.map_hover_text} placement="left">
					<path
						className={Style.select}
						onClick={() => onClick(6)}
						d="M1121 701.5L1185 604.5L1230 704L1197 744L1121 701.5Z"
						stroke="black"
					/>
				</CustomToolTip>
			</svg>
			{selected != null && (
				<PointsClicked
					data={data[selected].attributes}
					id={'map3-' + selected}
					onClose={onClose}
				/>
			)}
		</div>
	);
};

export default Points;
