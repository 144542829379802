import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './redux/reducer/auth.reducer';
import { toastMiddleware } from './redux/middleware/toast.middleware';
import { tokenMiddleware } from './redux/middleware/token.middleware';
import { dashboardDataReducer } from './redux/reducer/dashboard_data.reducer';
import { helperReducer } from './redux/reducer/helper.reducer';
import { urlSearchReducer } from './redux/reducer/url_search.reducer';
import { useDispatch } from 'react-redux';
import { audioReducer } from './redux/reducer/audio.reducer';
import { effectReducer } from './redux/reducer/effect.reducer';

const store = configureStore({
	reducer: {
		authReducer,
		dashboardDataReducer,
		helperReducer,
		urlSearchReducer,
		audioReducer,
		effectReducer
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(toastMiddleware, tokenMiddleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
