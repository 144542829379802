import { CSSProperties } from 'react';
import {
	InterludesData,
	SocialMediaLinksData,
	WebsiteConfigsData
} from '../../redux/constants/dashboard_objects';
import Styles from './interludes.module.scss';
import InterludeText from '../interlude_text/interlude_text';
import Interlude1 from '../interlude_1/interlude_1';
import ShareIcons from '../../helper/Share';
import Interlude2 from '../interlude_2/interlude_2';
import MenuItems from '../../helper/MenuItems';
import { useSelector } from 'react-redux';
import { urlSearchSelector } from '../../redux/reducer/url_search.reducer';
import GlobalAudio from '../global_audio/GlobalAudio';

interface customCSSProperties extends CSSProperties {
	'--themeColor': string | undefined;
	'--logo': string | undefined;
}

const Interludes = ({
	curPage,
	data,
	config,
	media
}: {
	curPage: string;
	data: InterludesData | undefined;
	config?: WebsiteConfigsData | undefined;
	media?: SocialMediaLinksData | undefined;
}) => {

	if (data == undefined) {
		return <div>interludes data not defined</div>;
	}

	const { page } = useSelector(urlSearchSelector);

	if (data == null || config == null || media == null) return <div>interludes</div>;

	if (data?.interlude_type === 'Description') {
		return <InterludeText curPage={curPage} data={data} config={config} />;
	}
	if (data?.interlude_type === 'Input Vertical') {
		return <Interlude1 curPage={curPage} data={data} config={config} />;
	}

	if (data?.interlude_type === 'Input Horizontal') {
		return <Interlude2 curPage={curPage} data={data} config={config} />;
	}

	const customStyles: customCSSProperties = {
		'--themeColor': data.theme,
		'--logo': `url(${(process.env.REACT_APP_BASE_URL ?? '') + data?.background_image?.data?.attributes?.url})`
	};

	return (
		<div className={Styles.main_body} style={customStyles}>
			<GlobalAudio />

			<img
				className={Styles.main_body_background}
				src={
					(process.env.REACT_APP_BASE_URL ?? '') +
					data?.background_image?.data?.attributes?.url
				}
				alt={data?.background_image?.data?.attributes?.alternativeText}
			/>
			<div className={Styles.custom_container}>
				<div className={Styles.video_container}>
					<img
						className={Styles.video_thumbnail + ' ' + (page == curPage ? Styles.active : '')}
						src={
							(process.env.REACT_APP_BASE_URL ?? '') +
							data?.featured_image?.data?.attributes?.url
						}
						alt={data?.featured_image?.data?.attributes?.alternativeText}
					/>
					<h1
						className={
							Styles.title_text + ' ' + (page == curPage ? Styles.title_text_active : '')
						}
					>
						INTERLUDE{' '}
					</h1>
					{/* <img onClick={openVideo} className={Styles.video_playbtn} src="./images/play_btn.png" alt="" /> */}
				</div>
				<div
					className={Styles.body_text + ' ' + (page === curPage ? Styles.active : '')}
					dangerouslySetInnerHTML={{ __html: data?.description }}
				></div>
				<div className={Styles.menu_bar}>
					<img
						src={
							(process.env.REACT_APP_BASE_URL ?? '') +
							config.interlude_logo.data.attributes?.url
						}
						alt={config.interlude_logo.data.attributes?.alternativeText}
						className={Styles.logo}
						onClick={() =>
							document.getElementById('homepage')?.scrollIntoView({ behavior: 'smooth' })
						}
					/>
					<div className={Styles.menu_items}>
						<MenuItems />
					</div>
				</div>
				<div className={Styles.random_image}>
					<img
						src={
							(process.env.REACT_APP_BASE_URL ?? '') +
							data?.mark_image?.data?.attributes?.url
						}
						alt={data?.mark_image?.data?.attributes?.alternativeText}
					/>
				</div>
				<div className={Styles.social_media_icons}>
					{/* TODO pass data to share icons */}
					{
						<ShareIcons
							text={`The city cultivates its own pauses; here within The Mapmaker from Baghdad, ${window.location.href}`}
						/>
					}
				</div>
			</div>
		</div>
	);
};

export default Interludes;
