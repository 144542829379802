import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Attributes } from '../constants/dashboard_objects';
import ContentService from '../service/content.service';

type InitialStateType = {
	isLoadingAnimationCompleted: boolean;
	loading: boolean;
	data: Attributes | null;
	error: string | null;
};
const initialState: InitialStateType = {
	isLoadingAnimationCompleted: false,
	loading: true,
	data: null,
	error: null
};

export const getDashboardData = createAsyncThunk('dashboard/getDashboardData', async () => {
	const [data, status] = await ContentService.getDashboardData();
	if (status !== 200 || !data) {
		console.log('error while getting dashboard data', status);
		throw new Error('Something went wrong');
	}
	return data.data;
});

const dashboardDataSlice = createSlice({
	name: 'dashboard',
	initialState: initialState,
	reducers: {
		loadingAnimationCompleted: (state) => {
			state.isLoadingAnimationCompleted = true;
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
		builder
			.addCase(getDashboardData.pending, (state) => {
				state.loading = true;
				state.data = null;
				state.error = null;
			})
			.addCase(getDashboardData.fulfilled, (state, action) => {
				state.data = action.payload.attributes;
				state.error = null;
				state.loading = false;
			})
			.addCase(getDashboardData.rejected, (state, action) => {
				state.data = null;
				state.error = action.error.message ?? 'Something went wrong';
				state.loading = false;
			});
	}
});

export const dashboardDataReducer = dashboardDataSlice.reducer;
export const dashboardDataAction = dashboardDataSlice.actions;
export const dashboardDataSelector = (state: RootState) => state.dashboardDataReducer;
