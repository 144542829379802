import axios from 'axios';
import { Attributes, BaseResponse } from '../constants/dashboard_objects';
import API from '../constants/api';
import MemoryRequest from '../../models/memory_request';
import { CustomHTTPClient } from './custom_client';

const CONTENT_TOKEN =
	'0d01474b4c2caed7194eb958266e628db9e5292005d7f6d95d75562cc9cab4b094210c7ad5fd8f0d8048949d94b5b67bc4f1463710ae513e0365135d4ecca7798e2cad7bb25c4c15581ccf7e5f554fd1759b2b3200db07f12bdc23b4d6786db01c7fa20d34ebe5508856a86ec9e2d7b56a27dbdb1dd5fa8fa256201a8e498038';
const MEMORY_TOKEN =
	'14f68840771e4e7c81e66da642245b1b0d745bc27cb9650f83dbf567cb06295e6ad6b319904faced5cfdb42a0dcb1bb08e1340bef69bbfed48068094e9ebd76cdf1a7343d2151e3c5548c1b7bd8eaa6b5e001af49cef143f3b49522881bbf1950a5f74c5c7199b41d1f920b567d7343af539df7150cf63cb490c278fb7ff86bc';
export default class ContentService {
	static async getDashboardData(): Promise<[BaseResponse<Attributes> | null, number]> {
		const response = await axios.get(API.DASHBOARD, {
			headers: {
				Authorization: `Bearer ${CONTENT_TOKEN}`
			}
		});
		return [response.data, response.status];
	}

	static async postMemory(data: MemoryRequest): Promise<void> {
		await CustomHTTPClient.post(
			API.ARCHIVE,
			{ data },
			{
				Authorization: `Bearer ${MEMORY_TOKEN}`
			}
		);
	}
}
