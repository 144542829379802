import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const urlSearchSlice = createSlice({
	name: 'urlSearch',
	initialState: {
		page: '',
		point: null
	},
	reducers: {
		update: (state, action) => {
			state.page = action.payload.page;
			state.point = action.payload.point;
		}
	}
});

export const urlSearchReducer = urlSearchSlice.reducer;
export const urlSearchAction = urlSearchSlice.actions;
export const urlSearchSelector = (state: RootState) => state.urlSearchReducer;
