import { ReactNode } from 'react';
import { authAction, AuthPage } from '../../../../redux/reducer/auth.reducer';
import { useAppDispatch } from '../../../../store';
import { ButtonConstants } from '../Constants';
import UserButtons, { ButtonData } from '../user_buttons/UserButtons';
import Styles from './LoginMessage.module.scss';

const LoginMessage = ({ title, children, leftButtonData, rightButtonData }:
    { title: string, children: ReactNode, leftButtonData?: ButtonData, rightButtonData?: ButtonData }) => {

    return (
        <div className={Styles.msg_outer}>
            <div className={Styles.title}>{title}</div>
            <div className={Styles.message}>{children}</div>
            <UserButtons leftButtonData={leftButtonData} rightButtonData={rightButtonData} />
        </div>
    );
}

export const ConfirmationComponent = () => {
    const dispatch = useAppDispatch();
    return <LoginMessage title=''
        leftButtonData={{
            text: ButtonConstants.GO_TO_LOGIN,
            onClick: () => {
                dispatch(authAction.update_state(AuthPage.LOGIN))
            }
        }} >
 Click on the link shared within the mail to begin your journey across the open expanse of <i>The Mapmaker from Baghdad</i>: select the fims, videos or the field recordings you want to include in your playlist, and respond to the questions the city asks of you.
    </LoginMessage>
}

export const WhySignUp = () => {
    const dispatch = useAppDispatch();
    return <LoginMessage title='why sign up?'
        leftButtonData={{
            text: ButtonConstants.GO_TO_SIGNUP,
            onClick: () => {
                dispatch(authAction.update_state(AuthPage.SIGNUP))
            }
        }}
        rightButtonData={{
            text: ButtonConstants.GO_TO_LOGIN,
            onClick: () => {
                dispatch(authAction.update_state(AuthPage.LOGIN))
            }
        }} >
      By signing up for the The Mapmaker from Baghdad, you will become a participant in the evolving design of the archive, receive regular updates about the project and
      related exhibitions, plus be able to <b>build your own playlist of material</b> gathered from across the map and thus, your own version of the city.
    </LoginMessage >
}



export default LoginMessage;