// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Grid_grid_container__64uRH {
  --scale: 1;
  display: grid;
  grid-template-columns: repeat(4, calc(1280px * var(--scale)));
  grid-template-rows: repeat(9, calc(800px * var(--scale)));
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  /* Enable scrolling within the container */
  /* Enable snapping to grid items */
  scroll-snap-type: both mandatory;
  scrollbar-width: none;
  align-items: center;
}

.Grid_vignette__cGteS {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
  pointer-events: none;
  opacity: 1;
  background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.Grid_show__R8uFf {
  opacity: 1 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/grid/Grid.module.scss"],"names":[],"mappings":"AAAA;EACC,UAAA;EACA,aAAA;EACA,6DAAA;EACA,yDAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,0CAAA;EAEA,kCAAA;EACA,gCAAA;EACA,qBAAA;EACA,mBAAA;AACD;;AAEA;EACC,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,oBAAA;EACA,UAAA;EACA,iFAAA;AACD;;AAEA;EACC,qBAAA;AACD","sourcesContent":[".grid_container {\n\t--scale: 1;\n\tdisplay: grid;\n\tgrid-template-columns: repeat(4, calc(1280px * var(--scale)));\n\tgrid-template-rows: repeat(9, calc(800px * var(--scale)));\n\twidth: 100vw;\n\theight: 100vh;\n\toverflow: scroll;\n\t/* Enable scrolling within the container */\n\t-webkit-scroll-snap-type: both mandatory;\n\t/* Enable snapping to grid items */\n\tscroll-snap-type: both mandatory;\n\tscrollbar-width: none;\n\talign-items: center;\n}\n\n.vignette {\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\tz-index: 30;\n\tpointer-events: none;\n\topacity: 1;\n\tbackground: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);\n}\n\n.show {\n\topacity: 1 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid_container": `Grid_grid_container__64uRH`,
	"vignette": `Grid_vignette__cGteS`,
	"show": `Grid_show__R8uFf`
};
export default ___CSS_LOADER_EXPORT___;
