// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fiction_fiction__QUBNF {
  position: relative;
  width: 1280px;
  height: 800px;
}
.fiction_fiction__QUBNF > * {
  position: absolute;
  width: 1280px;
  height: 800px;
}

.fiction_menu_bar__Mf4fr .fiction_menu_items__BV5qG {
  position: absolute;
  left: 29px;
  bottom: 124px;
  padding-right: 20px;
  padding-top: 20px;
}

.fiction_vertical_line__ehjSz {
  content: "";
  position: absolute;
  top: 50%;
  left: 60px;
  bottom: -20px;
  background: rgb(255, 254, 242);
  width: 1.5px;
}

.fiction_menu_bar__Mf4fr .fiction_menu_items__BV5qG ul {
  display: flex;
  flex-direction: column;
  gap: 37px;
  list-style: none;
}

.fiction_menu_bar__Mf4fr .fiction_menu_items__BV5qG ul li a {
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-family: ESR;
  font-size: 9pt;
  letter-spacing: 1px;
  transition: transform 0.3s ease;
  writing-mode: vertical-lr;
  /* text-orientation: upright; */
  transform: rotate(180deg);
}

.fiction_front__YeR63 {
  z-index: 2;
}

.fiction_content__BCNGr {
  color: white;
  font-family: FSB;
  font-size: 12px;
  width: 340px;
  line-height: 17px;
  margin-left: 837.7px;
  margin-top: 428px;
}`, "",{"version":3,"sources":["webpack://./src/componenets/fiction/fiction.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,aAAA;EACA,aAAA;AACD;AACC;EACC,kBAAA;EACA,aAAA;EACA,aAAA;AACF;;AAGA;EACC,kBAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;AAAD;;AAGA;EACC,WAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;EACA,aAAA;EACA,8BAAA;EACA,YAAA;AAAD;;AAGA;EACC,aAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AAAD;;AAGA;EACC,qBAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,+BAAA;EACA,yBAAA;EACA,+BAAA;EACA,yBAAA;AAAD;;AAGA;EACC,UAAA;AAAD;;AAGA;EACC,YAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;EACA,iBAAA;EACA,oBAAA;EACA,iBAAA;AAAD","sourcesContent":[".fiction {\n\tposition: relative;\n\twidth: 1280px;\n\theight: 800px;\n\n\t> * {\n\t\tposition: absolute;\n\t\twidth: 1280px;\n\t\theight: 800px;\n\t}\n}\n\n.menu_bar .menu_items {\n\tposition: absolute;\n\tleft: 29px;\n\tbottom: 124px;\n\tpadding-right: 20px;\n\tpadding-top: 20px;\n}\n\n.vertical_line {\n\tcontent: '';\n\tposition: absolute;\n\ttop: 50%;\n\tleft: 60px;\n\tbottom: -20px;\n\tbackground: rgb(255, 254, 242);\n\twidth: 1.5px;\n}\n\n.menu_bar .menu_items ul {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 37px;\n\tlist-style: none;\n}\n\n.menu_bar .menu_items ul li a {\n\ttext-decoration: none;\n\tcolor: white;\n\tfont-weight: 500;\n\tfont-family: ESR;\n\tfont-size: 9pt;\n\tletter-spacing: 1px;\n\ttransition: transform 0.3s ease;\n\twriting-mode: vertical-lr;\n\t/* text-orientation: upright; */\n\ttransform: rotate(180deg);\n}\n\n.front {\n\tz-index: 2;\n}\n\n.content {\n\tcolor: white;\n\tfont-family: FSB;\n\tfont-size: 12px;\n\twidth: 340px;\n\tline-height: 17px;\n\tmargin-left: 837.7px;\n\tmargin-top: 428px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fiction": `fiction_fiction__QUBNF`,
	"menu_bar": `fiction_menu_bar__Mf4fr`,
	"menu_items": `fiction_menu_items__BV5qG`,
	"vertical_line": `fiction_vertical_line__ehjSz`,
	"front": `fiction_front__YeR63`,
	"content": `fiction_content__BCNGr`
};
export default ___CSS_LOADER_EXPORT___;
