import { FormEvent, useState } from "react";
import { authAction, AuthPage, forgotPasswordUser } from "../../redux/reducer/auth.reducer";
import { ButtonConstants, SuccessMessage } from "./common/Constants";
import UserButtons from "./common/user_buttons/UserButtons";
import { EmailInput, UserError, UserMessage } from "./common/user_input/UserInput";
import { useAppDispatch } from "../../store";

const ForgotPasswordComponent = ({ email, setEmail }: { email: string, setEmail: (value: string) => void }) => {

    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();

    function resetError() {
        setEmailError(false);
        setError(null);
        setMessage(null);
    }

    async function submit(e: FormEvent) {
        e.preventDefault();
        if (loading) return;
        resetError();

        const [error] = await forgotPasswordUser(email);
        if (error) {
            setError(error);
        } else {
            setMessage(SuccessMessage.RESET_EMAIL_SEND)
        }
        setLoading(false);
    }

    function onGoToLogin() {
        if (loading) return;
        dispatch(authAction.update_state(AuthPage.LOGIN));
    }

    return (
        <form onSubmit={submit}>
            <EmailInput inputValue={email} setInputValue={setEmail} error={emailError} />
            <UserError error={error} />
            <UserMessage message={message} />
            <UserButtons
                leftButtonData={{ text: ButtonConstants.GO_TO_LOGIN, onClick: onGoToLogin }}
                rightButtonData={{ text: ButtonConstants.SUBMIT, onClick: () => { }, type: 'submit' }} />
        </form>
    )
}
export default ForgotPasswordComponent;