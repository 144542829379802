export const tokenMiddleware = (_: any) => {
	return function (next: any) {
		return function (action: any) {
			switch (action.type) {
				case 'auth/loginUser/fulfilled':
				case 'auth/signUpUser/fulfilled':
					localStorage.setItem('token', action.payload.jwt);
					break;
				case 'auth/logoutUser/fulfilled':
				case 'auth/verifyToken/rejected':
					localStorage.removeItem('token');
					break;
				default:
			}
			next(action);
		};
	};
};
