import { useEffect, useLayoutEffect, useMemo } from 'react';
import Styles from './Grid.module.scss';
import Episode from '../../componenets/expisode/episode';
import { Attributes } from '../../redux/constants/dashboard_objects';
import CycleCinemas from '../../componenets/cycle_cinemas/cycle_cinemas';
import Map1 from '../../componenets/map1/map1';
import Interludes from '../../componenets/interludes/interludes';
import UserPage from '../../componenets/user_page/UserPage';
import Homescreen from '../../componenets/homescreen/homescreen';
import About from '../../componenets/about/about';
import Fiction from '../../componenets/fiction/fiction';
import Prologue from '../../componenets/prologue/prologue';
import Epilogues from '../../componenets/epilogues/epilogues';
import Map5 from '../../componenets/map5/map5';
import GridAnimation from '../../componenets/comman_styles/GridAnimation';
import Map2 from '../../componenets/map2/map2';
import Map3 from '../../componenets/map3/map3';
import Map4 from '../../componenets/map4/map4';
import Map6 from '../../componenets/map6/map6';
import { useSearchParams } from 'react-router-dom';
import Archive from '../../componenets/archive/archive';
import { MenuItemsTypes } from '../../helper/MenuItems';

const Grid = ({ data }: { data: Attributes }) => {
	useLayoutEffect(() => {
		function updateSize() {
			const height = window.innerHeight / 800;
			const width = window.innerWidth / 1280;
			document
				.getElementById('main_grid')
				?.style?.setProperty('--scale', Math.min(height, width).toString());
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	const [param, setParam] = useSearchParams();

	useEffect(() => {
		const newpage = param.get('page') ?? 'homepage';
		document.getElementById(newpage)?.scrollIntoView({
			behavior: 'auto',
			block: 'center',
			inline: 'center'
		});
	}, []);

	const elements = useMemo(() => {
		const episodes = data?.episodes?.data;
		const cycle_cinema = data?.cycle_cinemas?.data;
		const interludes = data?.interludes?.data;
		const prologues = data?.prologues?.data;
		const epilogues = data?.epilogues?.data;
		const config = data?.website_configs?.data;
		const media = data?.social_media_links?.data;
		const home_bg = data?.map_images?.data?.[0]?.attributes?.home_bg;
		const images = data?.map_images?.data?.[0]?.attributes;

		return [
			//page 1 to 4
			<Episode
				key={1}
				data={episodes[0]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_1'}
			/>,
			<Episode
				key={2}
				data={episodes[1]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_2'}
			/>,
			<CycleCinemas key={3} data={cycle_cinema[0]?.attributes} />,
			<Episode
				key={4}
				data={episodes[2]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_4'}
			/>,
			//page 5 to 8
			<Map1 key={5} data={data.map_1s.data} image={images.map1_bg} />,
			<Interludes
				key={6}
				data={interludes[0]?.attributes}
				config={config[0].attributes}
				media={media[0].attributes}
				curPage={'page_6'}
			/>,
			<UserPage id={MenuItemsTypes.USER} key={11} data={config[0]?.attributes} />,
			<CycleCinemas key={8} data={cycle_cinema[1]?.attributes} />,
			//page 9 to 12
			<Interludes
				key={9}
				data={interludes[1]?.attributes}
				config={config[0].attributes}
				media={media[0].attributes}
				curPage={'page_9'}
			/>,
			<Episode
				key={12}
				data={episodes[3]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_10'}
			/>,
			<Epilogues
				key={19}
				data={epilogues[0]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_11'}
			/>,
			<Episode
				key={13}
				data={episodes[4]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_12'}
			/>,
			//page 13 to 16
			<Episode
				key={17}
				data={episodes[5]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_13'}
			/>,
			<CycleCinemas key={14} data={cycle_cinema[2]?.attributes} />,
			<Homescreen key={15} id={'homepage'} background={home_bg} />,
			<Prologue
				key={16}
				data={prologues[0]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_16'}
			/>,
			//page 17 to 20
			<Interludes
				key={24}
				data={interludes[2]?.attributes}
				config={config[0].attributes}
				media={media[0].attributes}
				curPage={'page_17'}
			/>,
			<Map2 key={10} data={data.map_2s.data} image={images.map2_bg} />,
			<Episode
				key={18}
				data={episodes[6]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_19'}
			/>,
			<Map3 key={20} data={data.map_3s.data} image={images.map3_bg} />,
			//page 21 to 24
			<Episode
				key={21}
				data={episodes[7]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_21'}
			/>,
			<CycleCinemas key={22} data={cycle_cinema[3]?.attributes} />,
			<About id={MenuItemsTypes.ABOUT} key={23} data={config[0]?.attributes} />,
			<Interludes
				key={25}
				data={interludes[3]?.attributes}
				config={config[0].attributes}
				media={media[0].attributes}
				curPage={'page_24'}
			/>,
			//page 25 to 28
			<Map4 key={28} data={data.map_4s.data} image={images.map4_bg} />,
			<Episode
				key={26}
				data={episodes[8]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_26'}
			/>,
			<CycleCinemas key={27} data={cycle_cinema[4]?.attributes} />,
			<Map5 key={29} data={data.map_5s.data} image={images.map5_bg} />,
			//page 29 to 32
			<Episode
				key={31}
				data={episodes[9]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_29'}
			/>,
			<Interludes
				key={30}
				data={interludes[4]?.attributes}
				config={config[0].attributes}
				media={media[0].attributes}
				curPage={'page_30'}
			/>,
			<Fiction id={MenuItemsTypes.FICTION} data={config[0]?.attributes} key={35} />,
			<Episode
				key={32}
				data={episodes[10]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_32'}
			/>,
			//page 33 to 36
			<Archive key={7} data={config[0]?.attributes} id={MenuItemsTypes.ARCHIVE} />,
			<Map6 key={34} data={data.map_6s.data} image={images.map6_bg} />,
			<Episode
				key={33}
				data={episodes[11]?.attributes}
				media={media[0]?.attributes}
				config={config[0]?.attributes}
				curPage={'page_35'}
			/>,
			<CycleCinemas key={36} data={cycle_cinema[5]?.attributes} />
		] as JSX.Element[];
	}, [data]);

	useEffect(() => {
		const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry) => {
				const { isIntersecting, target } = entry;
				const parentElement = target.parentElement;
				const elementId = parentElement?.id;
				const params = new URL(location.href).searchParams;
				const page = params.get('page');

				if (isIntersecting && elementId && page !== elementId) {
					setParam({ page: elementId });
				}

				if (isIntersecting) {
					parentElement?.classList.add(Styles.show);
				} else {
					parentElement?.classList.remove(Styles.show);
				}
			});
		});

		const gridItems = document.querySelectorAll('.grid_item_point_class');

		gridItems?.forEach((gridItem) => observer.observe(gridItem));

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<>
			<div className={Styles.vignette}></div>
			<div id="main_grid" className={Styles.grid_container}>
				<GridAnimation>{elements}</GridAnimation>
			</div>
		</>
	);
};

export default Grid;
