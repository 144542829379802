import Styles from './UserButtons.module.scss';
export interface ButtonData {
    text: string;
    onClick: () => void;
    type? : "button" | "submit" | "reset";
}

const UserButtons = ({ leftButtonData, rightButtonData }: { leftButtonData?: ButtonData, rightButtonData?: ButtonData }) => {
    return (
        <div className={Styles.buttons}>
            {
                leftButtonData ? <button type={leftButtonData.type ?? 'button'} className={Styles.leftButton} onClick={leftButtonData.onClick}>
                    {leftButtonData.text}
                </button>
                    : <div></div>
            }
            {
                rightButtonData ? <button type={rightButtonData.type ?? 'button'} className={Styles.rightButton} onClick={rightButtonData.onClick}>
                    {rightButtonData.text}
                </button>
                    : <div></div>
            }
        </div>
    )
}

export default UserButtons;