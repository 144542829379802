// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe {
	display: none;
}

body {
	overflow: hidden;
}

.MuiTooltip-arrow::before {
	color: white;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,YAAY;AACb","sourcesContent":["iframe {\n\tdisplay: none;\n}\n\nbody {\n\toverflow: hidden;\n}\n\n.MuiTooltip-arrow::before {\n\tcolor: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
