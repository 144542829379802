import Styles from './GridAnimation.module.scss';
import { useAppDispatch } from '../../store';
import { audioAction } from '../../redux/reducer/audio.reducer';

function ItemAnimation({ id, children }: { id: string; children: JSX.Element }) {
	const dispatch = useAppDispatch();

	const clicked = () => {
		dispatch(audioAction.onFirstInteraction());
	};

	return (
		<div id={id} onClick={clicked} className={Styles.hidden + ' ' + Styles.gridItem}>
			{children}
			<div className={Styles.point + ' ' + 'grid_item_point_class'}></div>
		</div>
	);
}

const GridAnimation = ({ children }: { children: JSX.Element[] }) => {
	return (
		<>
			{children.map((item, index) => (
				<ItemAnimation id={'page_' + (index + 1)} key={index}>
					{item}
				</ItemAnimation>
			))}
		</>
	);
};

export default GridAnimation;
