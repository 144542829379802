import { CSSProperties } from 'react';
import style from './archive.module.scss';
import { WebsiteConfigsData } from '../../redux/constants/dashboard_objects';

interface customCSSProperties extends CSSProperties {
	'--background': string | undefined;
}
const Archive = ({ id, data }: { data?: WebsiteConfigsData; id: string }) => {
	const customStyles: customCSSProperties = {
		'--background': `url(${(process.env.REACT_APP_BASE_URL ?? '') + data?.archive_bg?.data?.attributes?.url})`
	};
	return (
		<div className={style.bg} style={customStyles} id={id}>
			<h1 className={style.headline}>archive.</h1>
			<p className={style.text}>EVERY ONCE IN A WHILE, THE CITY YIELDS NEW DEBRIS HERE.</p>
		</div>
	);
};
export default Archive;
