import { useSearchParams } from 'react-router-dom';
import Style from '../comman_styles/points.module.scss';
import PointsClicked from '../tooltip/PointsClicked';
import { MapData } from '../../redux/constants/dashboard_objects';
import CustomToolTip from '../tooltip/ToolTip';
import { useSelector } from 'react-redux';
import { urlSearchSelector } from '../../redux/reducer/url_search.reducer';
import { useAppDispatch } from '../../store';
import { effectAction } from '../../redux/reducer/effect.reducer';

const Points = ({ data }: { data: { attributes: MapData; id: number }[] }) => {
	const curPage = 'page_28';
	const { page, point } = useSelector(urlSearchSelector);
	const [, setSearchParams] = useSearchParams();
	const selected = page == curPage ? point : null;
	const dispatch = useAppDispatch();

	const onClick = (index: number) => {
		dispatch(effectAction.onPointOpenEffect());
		setSearchParams({ page: curPage, point: index.toString() });
	};

	const onClose = () => {
		dispatch(effectAction.onPointCloseEffect());
		setSearchParams({ page: curPage });
	};

	return (
		<div style={{ position: 'relative' }}>
			<svg
				className={Style.map_svg}
				style={{ left: '-45px', top: '10px' }}
				width="1280"
				height="800"
				viewBox="0 0 1280 800"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
			>
				<CustomToolTip title={data[0]?.attributes.map_hover_text} placement="left">
					<path
						className={Style.select}
						onClick={() => onClick(0)}
						d="M845.5 286L860.5 276H871L884 278.5L894 292V307L890 318L904.5 322V330.5L900 335.5H890V349.5L900 356.5V372L884 384.5H860.5L843 370.5V356.5L852 347V329L843 322L839.5 307L845.5 286Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[1]?.attributes.map_hover_text} placement="left">
					<path
						className={Style.select}
						onClick={() => onClick(1)}
						d="M852 410.5H884L906.5 438.5H886L881 443L892.5 455.5L906.5 490L867 504L852 496.5L833 483L816 463V441L827 423L852 410.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[2]?.attributes.map_hover_text} placement="left">
					<path
						className={Style.select}
						onClick={() => onClick(2)}
						d="M862.5 552H723.5V614H862.5V552Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[3]?.attributes.map_hover_text} placement="right">
					<path
						className={Style.select}
						onClick={() => onClick(3)}
						d="M502.5 522.5L467 530L459.5 534.5L475 551V577L508.5 601.5L515.5 567L497.5 544.5L506 530L502.5 522.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[4]?.attributes.map_hover_text} placement="right">
					<path
						className={Style.select}
						onClick={() => onClick(4)}
						d="M422 412.5L414 420.5L402 408L385 420.5V516H444L427.5 473H452V448.5H447.5V425.5L435.5 412.5H422Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[5]?.attributes.map_hover_text} placement="right">
					<path
						className={Style.select}
						onClick={() => onClick(5)}
						d="M474 191L482 182L496.5 187V197L501.5 202.5V220.5L510 233.5V245.5L503.5 257.5L514.5 270.5V277.5L503.5 285.5H477.5L466.5 277.5L474 262L471 236L477.5 199L474 191Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[6]?.attributes.map_hover_text} placement="left">
					<path
						className={Style.select}
						onClick={() => onClick(6)}
						d="M724 207.5L756.5 187.5H773.5L791 201.5L812.5 210.5V229.5L780.5 254.5H756.5L749.5 240.5H740L726 229.5L724 207.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[7]?.attributes.map_hover_text} placement="right">
					<path
						className={Style.select}
						onClick={() => onClick(7)}
						d="M356.5 307L374 299.5L406.5 303.5L416.5 314V326L410 333H356.5L352 320L356.5 307Z"
						stroke="black"
					/>
				</CustomToolTip>
			</svg>
			{selected != null && (
				<PointsClicked
					data={data[selected].attributes}
					id={'map5-' + selected}
					onClose={onClose}
				/>
			)}
		</div>
	);
};

export default Points;
