// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map_relative__1Mvu8 {
  position: relative;
  width: 1280px;
  height: 800px;
}

.map_absolute__hROQN {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.map_contain__h7n-J {
  object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/componenets/map3/map.module.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,aAAA;EACA,aAAA;AACD;;AAEA;EACC,kBAAA;EACA,WAAA;EACA,YAAA;EACA,OAAA;EACA,MAAA;AACD;;AAEA;EACC,mBAAA;AACD","sourcesContent":[".relative {\n\tposition: relative;\n\twidth: 1280px;\n\theight: 800px;\n}\n\n.absolute {\n\tposition: absolute;\n\twidth: 100%;\n\theight: 100%;\n\tleft: 0;\n\ttop: 0;\n}\n\n.contain {\n\tobject-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"relative": `map_relative__1Mvu8`,
	"absolute": `map_absolute__hROQN`,
	"contain": `map_contain__h7n-J`
};
export default ___CSS_LOADER_EXPORT___;
