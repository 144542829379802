import React, { useState, useEffect, useRef, RefObject, useMemo } from 'react';
import styles from './AudioPlayer.module.scss';
import pauseSVG from './pause.svg';
import pplaySVG from './play-alt-2.svg';
import forwardSVG from './forward.svg';
import backwardSVG from './backward.svg';
import playlistPNG from './Playlist.png';
import shareSVG from './share.svg';
import { MapAudio } from '../../redux/constants/dashboard_objects';
import ShareIcons from '../../helper/Share';
import { useAppDispatch } from '../../store';
import { audioAction } from '../../redux/reducer/audio.reducer';

enum AudioStatus {
	Playing = 'Playing',
	Loading = 'Loading',
	Paused = 'Paused'
}

const AudioPlayer = ({
	currentSong,
	onClose,
	add,
	loading,
	added
}: {
	currentSong: MapAudio;
	onClose: () => void;
	add: () => void;
	loading: boolean;
	added: boolean;
}) => {
	const [isPlaying, setIsPlaying] = useState<AudioStatus>(AudioStatus.Loading);
	const [currentTime, setCurrentTime] = useState(0);
	const [progress, setProgress] = useState(0);
	const [duration, setDuration] = useState(0);
	const audioRef = useRef<HTMLAudioElement>(null);
	const sliderRef = useRef(null);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (currentTime === duration) {
			// Pause the player when the song ends
			setIsPlaying(AudioStatus.Paused);
		}
	}, [currentTime, duration]);

	const formatTime = (time: number) => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60)
			.toString()
			.padStart(2, '0');
		return `${minutes}:${seconds}`;
	};

	const handleTimeUpdate = () => {
		const audioEl = audioRef.current;
		if (audioEl == null) return;
		const currentTime = audioEl.currentTime;
		const duration = audioEl.duration;
		setProgress((currentTime / duration) * 100);
		setCurrentTime(currentTime);
	};

	const handleLoadedMetadata = () => {
		if (audioRef.current == null) return;
		setDuration(audioRef.current.duration);
	};

	const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (audioRef.current == null || event.target.value == null) return;
		const tempSliderValue = event.target.value;
		const duration = audioRef.current.duration;
		const newTime = (parseInt(tempSliderValue) / 100) * duration;
		audioRef.current.currentTime = newTime;
		setCurrentTime(newTime);
	};

	const audioUrl = useMemo(() => {
		return `In the distance somewhere, from within the cosmic din of the city, I heard, ‘${currentSong.title}’ in, ‘The Mapmaker from Baghdad’
        
        ${window.location.href}`;
	}, []);

	return (
		<div className={styles.main_container}>
			<div className={styles.audio_container}>
				<div
					className={styles.audio_header}
					onClick={() => {
						onClose();
						dispatch(audioAction.autoPlay());
					}}
				>
					x
				</div>
				<div className={styles.audio_content}>
					<div className={styles.audio_caption}>{currentSong.caption}</div>
					<div className={styles.audio_title}>{currentSong.title}</div>
				</div>
				<div className={styles.audio_controls_outer}>
					<div
						className={
							styles.audio_range +
							' ' +
							(isPlaying == AudioStatus.Playing ? styles.audio_playing : '')
						}
					>
						<input
							type="range"
							min="0"
							max="100"
							ref={sliderRef}
							value={progress}
							style={{
								background: `linear-gradient(to right, #ccc ${progress}%, #b6b1b159 ${progress}%)`
							}}
							onInput={handleInput}
						/>
						<div className={styles.audio_time}>
							<div className={styles.audio_start_time}>{formatTime(currentTime)}</div>
							<div className={`${styles.audio_end_time} value`}>
								{formatTime(duration)}
							</div>
						</div>
					</div>
					<Controlles
						shareUrl={audioUrl}
						audioRef={audioRef}
						isPlaying={isPlaying}
						setIsPlaying={setIsPlaying}
						setCurrentTime={setCurrentTime}
						currentSong={currentSong}
						add={add}
						loading={loading}
						added={added}
					/>
				</div>
			</div>
			<audio
				onPlay={() => {
					dispatch(audioAction.autoPause());
				}}
				onPlaying={() => setIsPlaying(AudioStatus.Playing)}
				onTimeUpdate={handleTimeUpdate}
				onLoadedMetadata={handleLoadedMetadata}
				ref={audioRef}
				src={(process.env.REACT_APP_BASE_URL ?? '') + currentSong.audiofile.data.attributes.url}
				className={isPlaying ? styles.audio_playing : ''}
			/>
		</div>
	);
};

const Controlles = ({
	audioRef,
	isPlaying,
	setIsPlaying,
	setCurrentTime,
	currentSong,
	add,
	loading,
	added
}: {
	shareUrl: string;
	audioRef: RefObject<HTMLAudioElement>;
	isPlaying: AudioStatus;
	setIsPlaying: (isPlaying: AudioStatus) => void;
	setCurrentTime: (currentTime: number) => void;
	currentSong: MapAudio;
	add: () => void;
	loading: boolean;
	added: boolean;
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const handlePlayPause = () => {
		const audioEl = audioRef.current;
		if (audioEl == null) return;
		if (isPlaying == AudioStatus.Playing) {
			setIsPlaying(AudioStatus.Paused);
			audioEl.pause();
		}
		if (isPlaying == AudioStatus.Paused) {
			setIsPlaying(AudioStatus.Loading);
			audioEl.play();
		}
	};

	const handleBackward = () => {
		const audioEl = audioRef.current;
		if (audioEl == null) return;
		audioEl.currentTime = Math.max(0, audioEl.currentTime - 3);
		setCurrentTime(audioEl.currentTime);
	};

	const handleForward = () => {
		const audioEl = audioRef.current;
		if (audioEl == null) return;
		audioEl.currentTime = Math.min(audioEl.duration, audioEl.currentTime + 3);
		setCurrentTime(audioEl.currentTime);
	};

	const shareAudioUrl = `In the distance somewhere, from within the cosmic din of the city, I heard, ${currentSong.title}in,${window.location.href}`;

	return (
		<div className={styles.audio_controls}>
			<div className={styles.audio_control_btns} id="favorite" onClick={add}>
				{loading ? (
					<img
						style={{ height: '20px', width: '32px' }}
						className={styles.loadingBar}
						src="./images/loadingBar.gif"
						alt="Remove from Playlist"
					/>
				) : added ? (
					<img src="./images/remove_playlist.png" alt="Remove from Playlist" />
				) : (
					<img src={playlistPNG} alt="Add to Playlist" />
				)}
			</div>
			<div className={styles.audio_control_btns} id="backward" onClick={handleBackward}>
				<img src={backwardSVG} alt="" />
			</div>
			<div className={styles.audio_control_btns} id="play_pause" onClick={handlePlayPause}>
				{isPlaying == AudioStatus.Paused ? (
					<img src={pplaySVG} />
				) : (
					<img
						style={{ cursor: isPlaying == AudioStatus.Loading ? 'wait' : 'pointer' }}
						src={pauseSVG}
					/>
				)}
			</div>
			<div className={styles.audio_control_btns} id="forward" onClick={handleForward}>
				<img src={forwardSVG} alt="" />
			</div>

			{/* handleShare(); you can use this to directly copy link */}
			<div
				className={styles.audio_control_btns + ' ' + styles.audio_share}
				id="share"
				onClick={() => {
					if (isHovered) {
						setIsHovered(false);
					} else {
						setIsHovered(true);
					}
				}}
			>
				<div className={`${styles.social_audio_media} ${isHovered ? styles.hover_active : ''}`}>
					<ShareIcons text={shareAudioUrl} />{' '}
				</div>
				<img className={styles.audio_share_img} src={shareSVG} alt="" />
			</div>
		</div>
	);
};

export default AudioPlayer;
