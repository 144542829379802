import API from '../constants/api';
import { CustomHTTPClient } from './custom_client';

class AuthService {
	static async loginUser(email: string, password: string) {
		const response = await CustomHTTPClient.post(API.SIGNIN, {
			identifier: email,
			password
		});
		return response.data;
	}

	static async signUpUser(email: string, password: string, fullname: string) {
		const response = await CustomHTTPClient.post(API.SIGNUP, {
			email,
			password,
			username: email,
			fullname,
		});

		return response.data;
	}

	static async forgotPassword(email: string) {
		const response = await CustomHTTPClient.post(API.FORGOT, {
			email
		});

		return response.data;
	}

	static async resetPassword(code: string, password: string, passwordConfirmation: string) {
		const response = await CustomHTTPClient.post(API.RESET, {
			code,
			password,
			passwordConfirmation
		});

		return response.data;
	}

	static async logoutUser(token: string) {
		const response = await CustomHTTPClient.post(API.SIGNOUT, null, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return response.data;
	}

	static async verifyToken(token: string) {
		const response = await CustomHTTPClient.get(API.VERIFY_TOKEN, {
			Authorization: `Bearer ${token}`
		});
		return response.data;
	}

	static async addToPlaylist(
		token: string,
		user_id: number,
		playlist_id: string,
		playlist: any,
	): Promise<number> {
		const response = await CustomHTTPClient.post(
			API.ADD_TO_PLAYLIST,
			{
				data: {
					playlist: playlist,
					unique: playlist_id,
					user: user_id
				}
			},
			{
				Authorization: `Bearer ${token}`
			}
		);
		return response.status;
	}

	static async deleteFromPlaylist(token: string, playlist_id: number): Promise<number> {
		const response = await CustomHTTPClient.delete(API.ADD_TO_PLAYLIST + '/' + playlist_id, {
			Authorization: `Bearer ${token}`
		});
		return response.status;
	}
}

export default AuthService;
