import { CSSProperties } from 'react';
import {
	ProloguesData,
	SocialMediaLinksData,
	WebsiteConfigsData
} from '../../redux/constants/dashboard_objects';
import Styles from './prologue.module.scss';
import ShareIcons from '../../helper/Share';
import MenuItems from '../../helper/MenuItems';
import { useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { addToFavorites, authSelector, deleteFavorites } from '../../redux/reducer/auth.reducer';
import VideoOverlay from '../video_player/video_player';
import { urlSearchSelector } from '../../redux/reducer/url_search.reducer';
import { useSearchParams } from 'react-router-dom';
import { MenuItemsTypes } from '../../helper/MenuItems';
import GlobalAudio from '../global_audio/GlobalAudio';

interface customCSSProperties extends CSSProperties {
	'--themeColor': string | undefined;
	'--background': string | undefined;
}

const Prologue = ({
	curPage,
	data,
	config,
	media
}: {
	curPage: string;
	data: ProloguesData | undefined;
	config?: WebsiteConfigsData | undefined;
	media?: SocialMediaLinksData | undefined;
}) => {
	if (data == null || config == null || media == null) return <div>prologue</div>;

	const customStyles: customCSSProperties = {
		'--themeColor': data.theme,
		'--background': `url(${(process.env.REACT_APP_BASE_URL ?? '') + data?.grains_image?.data?.attributes?.url})`
	};

	const dispatch = useAppDispatch();
	const { user, loading } = useSelector(authSelector);

	const { page, point } = useSelector(urlSearchSelector);
	const [, setSearchParams] = useSearchParams();

	const addToPlaylist = () => {
		if (user == null || loading) {
			document.getElementById(MenuItemsTypes.USER)?.scrollIntoView({ behavior: 'smooth' });
			return;
		}
		// if user is not logged in, redirect to login page
		const add = added();
		if (add) {
			dispatch(deleteFavorites({ id: add.id }));
		} else {
			dispatch(
				addToFavorites({
					user_id: user.id,
					playlist_id: user.id + '_' + page,
					playlist: data
				})
			);
		}
	};

	const added = () => {
		return user?.playlists?.find((playlist) => playlist.unique === user.id + '_' + page);
	};

	const closeVideo = () => {
		setSearchParams({ page: curPage });
	};

	const openVideo = () => {
		setSearchParams({ page: curPage, point: 'video' });
	};

	return (
		<div className={Styles.main_body} style={customStyles}>
			<GlobalAudio />
			<div style={{ zIndex: '100', position: 'relative' }}>
				{page == curPage && point == 'video' ? (
					<VideoOverlay
						noBg={true}
						disableDetails={true}
						video={data?.map_video?.data?.attributes}
						onClose={closeVideo}
						add={addToPlaylist}
						loading={loading}
						added={added() != null}
					/>
				) : <></>}
			</div>
			<img
				className={Styles.main_body_background}
				src={
					(process.env.REACT_APP_BASE_URL ?? '') +
					data?.background_image?.data?.attributes?.url
				}
				alt={data?.background_image?.data?.attributes?.alternativeText}
			/>
			<div className={Styles.custom_container}>
				<div className={Styles.video_container}>
					<img
						className={
							Styles.video_thumbnail +
							' ' +
							(page === curPage ? Styles.video_thumbnail_active : '')
						}
						src={
							(process.env.REACT_APP_BASE_URL ?? '') +
							data?.featured_image?.data?.attributes?.url
						}
						alt={data?.featured_image?.data?.attributes?.alternativeText}
					/>
					<h1
						className={
							Styles.title_text + ' ' + (page === curPage ? Styles.title_text_active : '')
						}
					>
						{data.title}
					</h1>
					<img
						onClick={openVideo}
						className={Styles.video_playbtn}
						src="./images/play_btn.png"
						alt="play button"
					/>
				</div>
				<div
					className={
						Styles.body_text + ' ' + (page === curPage ? Styles.body_text_active : '')
					}
				>
					{data.description}
				</div>
				<div className={Styles.menu_bar}>
					<img
						onClick={() =>
							document.getElementById('homepage')?.scrollIntoView({
								behavior: 'smooth',
								block: 'center',
								inline: 'center'
							})
						}
						src={
							(process.env.REACT_APP_BASE_URL ?? '') +
							config?.prologue_logo?.data?.attributes?.url
						}
						alt={data?.featured_image?.data?.attributes?.alternativeText}
						className={Styles.logo}
					/>
					<div className={Styles.menu_items}>
						<MenuItems />
					</div>
				</div>

				<div className={Styles.social_media_icons}>
					<ShareIcons
						text={`It is here that our account of Rafique’s dreaded night begins; the prologue to a haunting, ‘The Thousand Clones of S Bhise’, from ‘The Mapmaker from Baghdad’, is available here: ${window.location.href}`}
					/>
				</div>
			</div>
		</div>
	);
};

export default Prologue;
