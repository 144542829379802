import { useEffect, useMemo, useRef, useState } from "react";
import { logoutUser, UserType } from "../../../redux/reducer/auth.reducer";
import { useAppDispatch } from "../../../store";
import { ButtonConstants } from "../common/Constants";
import LoginMessage from "../common/login_message/LoginMessage";
import Styles from "./LoggedIn.module.scss";
import { PointsClickedData } from "../../tooltip/PointsClicked";

export default function LoggedIn({ user, onHomePage, setShowMedia }: { user: UserType, onHomePage: () => void, setShowMedia: (item: { id: number; playlist: PointsClickedData; unique: string; }) => void }) {
    const dispatch = useAppDispatch();
    return <LoginMessage title='playlist'
        leftButtonData={{
            text: ButtonConstants.LOGOUT,
            onClick: () => {
                dispatch(logoutUser());
            }
        }}
        rightButtonData={{
            text: ButtonConstants.ENTER_ARCHIVE,
            onClick: onHomePage
        }} >
        <LoggedInBody user={user} setShowMedia={setShowMedia} />
    </LoginMessage>
}

function LoggedInBody({ user, setShowMedia }: { user: UserType, setShowMedia: (item: { id: number; playlist: PointsClickedData; unique: string; }) => void }) {
    if (user?.playlists == null || user?.playlists?.length == 0) {
        return <>
            You are now logged in! Add items from across the map into your infinte playlist. Visit this page anytime to toggle down your personal archive of the city.
        </>
    }

    const playlistRef = useRef<HTMLDivElement>(null);
    const moreRef = useRef<HTMLDivElement>(null);
    const [scrollInterval, setScrollInterval] = useState<NodeJS.Timer | null>(null);

    function startScrolling() {
        if (playlistRef.current == null) return;
        setScrollInterval(setInterval(() => {
            if (playlistRef.current == null) return;
            playlistRef.current.scrollBy({ top: 10, behavior: 'smooth' });
        }, 100));
    }

    function stopScrolling() {
        if (scrollInterval) {
            clearInterval(scrollInterval);
            setScrollInterval(null);
        }
    }

    useEffect(() => {
        if (moreRef.current == null) return;

        // Start scrolling on mousedown
        moreRef.current.addEventListener('mousedown', startScrolling);

        // Stop scrolling on mouseup and mouseleave
        moreRef.current.addEventListener('mouseup', stopScrolling);
        moreRef.current.addEventListener('mouseleave', stopScrolling);

        return () => {
            moreRef.current?.removeEventListener('mousedown', startScrolling);
            moreRef.current?.removeEventListener('mouseup', stopScrolling);
            moreRef.current?.removeEventListener('mouseleave', stopScrolling);
        };
    }, [user?.playlists, scrollInterval]);

    return <>
        <div className={Styles.container}>
            <div className={Styles.playlists} ref={playlistRef}>
                {user?.playlists?.map((item, index) => (
                    <PlayList key={index} onClick={() => setShowMedia(item)} data={item} />
                ))}
            </div>
            <div className={Styles.more} ref={moreRef}>
                <div className={Styles.more_text}>MORE</div>
                <div className={Styles.more_icon}>{'>'}</div>
            </div>
        </div>
    </>
}


const PlayList = ({
    data,
    onClick
}: {
    data: { id: number; playlist: PointsClickedData; unique: string };
    onClick: () => void;
}) => {
    const title = useMemo(() => {
        switch (data?.playlist?.point_type ?? "Video") {
            case 'Video':
                return data?.playlist?.map_video?.data?.attributes?.video_title ?? "";
            case 'Gallery':
                return data?.playlist?.map_gallery?.data?.attributes?.gallery_title ?? "";
            case 'Audio':
                return data?.playlist?.map_audio?.data?.attributes?.title ?? "";
        }
    }, [data.playlist]);
    return (
        <div className={Styles.playlist} onClick={onClick}>
            <div className={Styles.playlist_title}>{title}</div>
            <div className={Styles.playlist_icon}>{'>'}</div>
        </div>
    );
};