/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Points from './points';
import Styles from './map.module.scss';
import mapStyles from '../comman_styles/points.module.scss';
import { Image, MapData } from '../../redux/constants/dashboard_objects';
import GlobalAudio from '../global_audio/GlobalAudio';

const Map = ({ data, image }: { data: { attributes: MapData; id: number }[]; image: Image }) => {
	return (
		<div className={Styles.relative}>
			<GlobalAudio />
			<img
				className={Styles.absolute}
				style={{ zIndex: -1 }}
				src={(process.env.REACT_APP_BASE_URL ?? '') + image?.data?.attributes?.url}
				alt={image?.data?.attributes?.alternativeText}
			></img>
			<img
				className={Styles.absolute + ' ' + Styles.contain + ' ' + mapStyles.glow}
				style={{ zIndex: 1 }}
				src="./images/maps/map_6_links.png"
			></img>
			<div className={Styles.absolute} style={{ zIndex: 2 }}>
				<Points data={data} />
			</div>
		</div>
	);
};

export default Map;
