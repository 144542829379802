import { MouseEvent } from 'react';
import Styles from './MenuItems.module.scss';

export enum MenuItemsTypes {
	FICTION = 'fictionpage',
	ABOUT = 'aboutpage',
	USER = 'userpage',
	ARCHIVE = 'archivepage'
}
const MenuItems = () => {
	const handleClick = (e: MouseEvent, id: string) => {
		e.preventDefault();
		const element = document.getElementById(id);
		element?.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<ul className={Styles.menu_transition_animation}>
			<li>
				<a onClick={(e) => handleClick(e, MenuItemsTypes.FICTION)} data-replace="Fiction">
					<span>Fiction</span>
				</a>
			</li>
			<li>
				<a onClick={(e) => handleClick(e, MenuItemsTypes.ABOUT)} data-replace="About">
					<span>About</span>
				</a>
			</li>
			<li>
				<a onClick={(e) => handleClick(e, MenuItemsTypes.USER)} data-replace="User">
					<span>User</span>
				</a>
			</li>
			<li>
				<a onClick={(e) => handleClick(e, MenuItemsTypes.ARCHIVE)} data-replace="Archive">
					<span>Archive</span>
				</a>
			</li>
		</ul>
	);
};

export default MenuItems;
