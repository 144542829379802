import { useState } from 'react';
import styles from './Carousel.module.scss';
import { MapGallery } from '../../redux/constants/dashboard_objects';

const Carousel = ({ gallery, onClose }: { gallery: MapGallery; onClose: () => void }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const carouselLength = gallery.gallery_images.data.length;

	const [addClass, setAddClass] = useState('');

	const changeIndex = async (index: number) => {
		if (index === 1) setAddClass(styles.animateRight);
		if (index === -1) setAddClass(styles.animateLeft);
		if (index === 1 || index === -1) await new Promise((resolve) => setTimeout(resolve, 1000));
		setCurrentIndex((currentIndex + index + carouselLength) % carouselLength);
		setAddClass('');
	};
	const getImage = (index: number) => {
		return (
			process.env.REACT_APP_BASE_URL +
			gallery.gallery_images.data[(currentIndex + index + carouselLength) % carouselLength]
				.attributes.url
		);
	};

	return (
		<div className={styles.sixthContainer}>
			<div className={styles.sixthContainerInner + ' ' + addClass}>
				<div
					className={`${styles.sixthCol} ${styles.show} ${styles.sixthZero}`}
					onClick={() => changeIndex(-3)}
					style={{ backgroundImage: `url(${getImage(-3)})` }}
				></div>
				<div
					className={`${styles.sixthCol} ${styles.show} ${styles.sixthFirst}`}
					onClick={() => changeIndex(-2)}
					style={{ backgroundImage: `url(${getImage(-2)})` }}
				></div>
				<div
					className={`${styles.sixthCol} ${styles.show} ${styles.sixthSecond}`}
					onClick={() => changeIndex(-1)}
					style={{ backgroundImage: `url(${getImage(-1)})` }}
				></div>
				<div
					className={`${styles.sixthCol} ${styles.show} ${styles.sixthThird}`}
					style={{ backgroundImage: `url(${getImage(0)})` }}
				></div>
				<div
					className={`${styles.sixthCol} ${styles.show} ${styles.sixthForth}`}
					onClick={() => changeIndex(1)}
					style={{ backgroundImage: `url(${getImage(1)})` }}
				></div>
				<div
					className={`${styles.sixthCol} ${styles.show} ${styles.sixthFifth}`}
					onClick={() => changeIndex(2)}
					style={{ backgroundImage: `url(${getImage(2)})` }}
				></div>
				<div
					className={`${styles.sixthCol} ${styles.show} ${styles.sixthSixth}`}
					onClick={() => changeIndex(3)}
					style={{ backgroundImage: `url(${getImage(3)})` }}
				></div>
			</div>
			<div className={styles.sixthDots}>
				{gallery.gallery_images.data.map((_, index) => (
					<div
						key={index}
						className={`${styles.sixthDot} ${index === currentIndex ? styles.sixthDotActive : ''}`}
						onClick={() => changeIndex(index - currentIndex)}
					/>
				))}
			</div>
			<div className={styles.carousel_title}>
				<div className={styles.carousel_main_title}>PHOTO GALLERY</div>
				<div className={styles.carousel_main_sub_title}>{gallery.gallery_title}</div>
				<div className={styles.carousel_main_desc}>{gallery?.desc}</div>
			</div>
			<div className={styles.carousel_close_btn} onClick={onClose}>
				X
			</div>
		</div>
	);
};

export default Carousel;
