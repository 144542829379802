// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_background__LLyZD {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
}
.Home_background__LLyZD .Home_unmute__NtsJz {
  box-sizing: content-box;
  padding: 40px;
  width: 18px;
  height: 24px;
}

.Home_container__36mq4 {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 99;
}

.Home_hidden__Qk\\+D1 {
  animation: Home_fade-in__bhQTH 2s forwards;
}

@keyframes Home_fade-in__bhQTH {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.Home_main__hS8zV {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/Home.module.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,oBAAA;AACD;AACC;EACC,uBAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;AACF;;AAGA;EACC,oBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,WAAA;AAAD;;AAGA;EACC,0CAAA;AAAD;;AAGA;EACC;IACC,UAAA;EAAA;EAGD;IACC,UAAA;EADA;AACF;AAIA;EACC,WAAA;EACA,YAAA;AAFD","sourcesContent":[".background {\n\twidth: 100%;\n\theight: 100%;\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\tz-index: 101;\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tpointer-events: none;\n\n\t.unmute {\n\t\tbox-sizing: content-box;\n\t\tpadding: 40px;\n\t\twidth: 18px;\n\t\theight: 24px;\n\t}\n}\n\n.container {\n\tpointer-events: none;\n\tposition: absolute;\n\twidth: 100%;\n\theight: 100%;\n\tbackground-color: black;\n\tz-index: 99;\n}\n\n.hidden {\n\tanimation: fade-in 2s forwards;\n}\n\n@keyframes fade-in {\n\t0% {\n\t\topacity: 1;\n\t}\n\n\t100% {\n\t\topacity: 0;\n\t}\n}\n\n.main {\n\twidth: 100%;\n\theight: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `Home_background__LLyZD`,
	"unmute": `Home_unmute__NtsJz`,
	"container": `Home_container__36mq4`,
	"hidden": `Home_hidden__Qk+D1`,
	"fade-in": `Home_fade-in__bhQTH`,
	"main": `Home_main__hS8zV`
};
export default ___CSS_LOADER_EXPORT___;
