import { useEffect, useRef } from 'react';
import Grid from '../grid/Grid';
import { AppDispatch, useAppDispatch } from '../../store';
import { dashboardDataSelector, getDashboardData } from '../../redux/reducer/dashboard_data.reducer';
import { useSelector } from 'react-redux';
import { verifyToken } from '../../redux/reducer/auth.reducer';
import { useSearchParams } from 'react-router-dom';
import { urlSearchAction } from '../../redux/reducer/url_search.reducer';
import { audioSelector } from '../../redux/reducer/audio.reducer';
import Styles from './Home.module.scss';
import LoadingScreen from '../../componenets/loading_screen/LoadingScreen';
import unmute_png from './unmute.png';
import { effectSelector } from '../../redux/reducer/effect.reducer';

const Home = () => {
	const dispatch = useAppDispatch();
	const audioRef = useRef<HTMLAudioElement>(null);
	const pointOpeningEffectRef = useRef<HTMLAudioElement>(null);
	const pointClosingEffectRef = useRef<HTMLAudioElement>(null);
	const { isPausedByUser, isAutoPaused, isFirstInteraction } = useSelector(audioSelector);
	const { onPointStatusChange } = useSelector(effectSelector);

	useEffect(() => {
		dispatch(getDashboardData());
		dispatch(verifyToken());
	}, []);

	useEffect(() => {
		if (audioRef?.current == null) return;
		try {
			if (isAutoPaused || isPausedByUser || isFirstInteraction == false) {
				audioRef?.current?.pause();
			} else {
				audioRef?.current?.play();
			}
		} catch (error) {
			console.log(error);
		}
	}, [isAutoPaused, isPausedByUser, isFirstInteraction, audioRef]);

	const [param] = useSearchParams();
	useEffect(() => {
		const page = param.get('page');
		const point = param.get('point');
		dispatch(urlSearchAction.update({ page: page, point: point }));
	}, [param]);

	useEffect(() => {
		if (onPointStatusChange === true) {
			pointOpeningEffectRef?.current?.play();
		} else if (onPointStatusChange === false) {
			pointClosingEffectRef?.current?.play();
		}
	}, [onPointStatusChange]);

	return (
		<>
			<audio ref={audioRef} loop={true} src="./global_audio.wav" />
			<audio src="./projector.wav" ref={pointOpeningEffectRef} />
			<audio src="./projector_close.wav" ref={pointClosingEffectRef} />
			{isFirstInteraction === false && (
				<div className={Styles.background}>
					<img className={Styles.unmute} src={unmute_png} alt="unmute" />
				</div>
			)}
			<HomeBody dispatch={dispatch} />
		</>
	);
};

const HomeBody = ({ dispatch }: { dispatch: AppDispatch }) => {
	const { loading, isLoadingAnimationCompleted, data, error } = useSelector(dashboardDataSelector);

	if (error || (!data && loading == false)) {
		return (
			<div>
				<div>{error}</div>
				<button onClick={() => dispatch(getDashboardData())}>Retry</button>
			</div>
		);
	}

	return (
		<div className={Styles.main}>
			{(loading || isLoadingAnimationCompleted == false) && <LoadingScreen />}
			<div
				className={
					Styles.container +
					' ' +
					(loading == false && isLoadingAnimationCompleted ? Styles.hidden : '')
				}
			/>
			{data && <Grid data={data} />}
		</div>
	);
};
export default Home;
