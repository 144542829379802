// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.points_glow__lRWSq {
  transform-box: fill-box;
  --color: white;
  fill: transparent;
  cursor: pointer;
  stroke-width: 1;
  stroke: var(--color);
  overflow: visible;
  animation: points_svg-shadow__qqlSp 1.5s ease-in-out infinite alternate;
}

.points_glow_test__m5qMo:hover {
  stroke: white !important;
}

@keyframes points_svg-shadow__qqlSp {
  from {
    filter: drop-shadow(0 0 1.8px var(--color)) drop-shadow(0 0 2.6px var(--color)) drop-shadow(0 0 2.6px var(--color));
  }
  to {
    filter: drop-shadow(0 0 -1px var(--color)) drop-shadow(0 0 -1px var(--color)) drop-shadow(0 0 -1px var(--color));
  }
}
.points_select__6xpvS {
  pointer-events: all;
  stroke: transparent;
  cursor: pointer;
  outline: unset;
}

.points_map_svg__fsBR4 {
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/componenets/comman_styles/points.module.scss"],"names":[],"mappings":"AAAA;EACC,uBAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;EACA,oBAAA;EACA,iBAAA;EACA,uEAAA;AACD;;AAGC;EACC,wBAAA;AAAF;;AAIA;EACC;IACC,mHAAA;EADA;EAKD;IACC,gHAAA;EAHA;AACF;AAOA;EACC,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;AALD;;AAQA;EACC,kBAAA;AALD","sourcesContent":[".glow {\n\ttransform-box: fill-box;\n\t--color: white;\n\tfill: transparent;\n\tcursor: pointer;\n\tstroke-width: 1;\n\tstroke: var(--color);\n\toverflow: visible;\n\tanimation: svg-shadow 1.5s ease-in-out infinite alternate;\n}\n\n.glow_test {\n\t&:hover {\n\t\tstroke: #ffffffff !important;\n\t}\n}\n\n@keyframes svg-shadow {\n\tfrom {\n\t\tfilter: drop-shadow(0 0 1.8px var(--color)) drop-shadow(0 0 2.6px var(--color))\n\t\t\tdrop-shadow(0 0 2.6px var(--color));\n\t}\n\n\tto {\n\t\tfilter: drop-shadow(0 0 -1px var(--color)) drop-shadow(0 0 -1px var(--color))\n\t\t\tdrop-shadow(0 0 -1px var(--color));\n\t}\n}\n\n.select {\n\tpointer-events: all;\n\tstroke: transparent;\n\tcursor: pointer;\n\toutline: unset;\n}\n\n.map_svg {\n\tposition: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"glow": `points_glow__lRWSq`,
	"svg-shadow": `points_svg-shadow__qqlSp`,
	"glow_test": `points_glow_test__m5qMo`,
	"select": `points_select__6xpvS`,
	"map_svg": `points_map_svg__fsBR4`
};
export default ___CSS_LOADER_EXPORT___;
