import { CSSProperties } from 'react';
import {
	EpisodesData,
	SocialMediaLinksData,
	WebsiteConfigsData
} from '../../redux/constants/dashboard_objects';
import Styles from './episode.module.scss';
import ShareIcons from '../../helper/Share';
import { addToFavorites, authSelector, deleteFavorites } from '../../redux/reducer/auth.reducer';
import { useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import VideoOverlay from '../video_player/video_player';
import MenuItems from '../../helper/MenuItems';
import { urlSearchSelector } from '../../redux/reducer/url_search.reducer';
import { useSearchParams } from 'react-router-dom';
import { MenuItemsTypes } from '../../helper/MenuItems';
import GlobalAudio from '../global_audio/GlobalAudio';

interface customCSSProperties extends CSSProperties {
	'--themeColor': string | undefined;
	'--background': string | undefined;
}

const Episode = ({
	curPage,
	data,
	config,
	media
}: {
	curPage: string;
	data: EpisodesData | undefined;
	config?: WebsiteConfigsData | undefined;
	media?: SocialMediaLinksData | undefined;
}) => {
	if (data == undefined) {
		return <div>Episode undefined data</div>;
	}

	const dispatch = useAppDispatch();
	const { user, loading } = useSelector(authSelector);
	const { page, point } = useSelector(urlSearchSelector);
	const [, setSearchParams] = useSearchParams();

	const addToPlaylist = () => {
		if (user == null || loading) {
			document.getElementById(MenuItemsTypes.USER)?.scrollIntoView({ behavior: 'smooth' });
			return;
		}
		// if user is not logged in, redirect to login page
		const add = added();
		if (add) {
			dispatch(deleteFavorites({ id: add.id }));
		} else {
			dispatch(
				addToFavorites({
					user_id: user.id,
					playlist_id: user.id + '_' + page,
					playlist: data
				})
			);
		}
	};

	const added = () => {
		return user?.playlists?.find((playlist) => playlist.unique === user.id + '_' + page);
	};

	const closeVideo = () => {
		setSearchParams({ page: curPage });
	};

	const openVideo = () => {
		setSearchParams({ page: curPage, point: 'video' });
	};

	if (data == null || media == null) {
		return <div>Episode</div>;
	}

	const setShowStory = () => {
		if (page != curPage) return;
		if (point == 'story') {
			setSearchParams({ page: curPage });
		} else {
			setSearchParams({ page: curPage, point: 'story' });
		}
	};

	const customStyles: customCSSProperties = {
		'--themeColor': data.theme,
		'--background': `url(${(process.env.REACT_APP_BASE_URL ?? '') + data?.background_image?.data?.attributes?.url})`
	};

	return (
		<div className={Styles.main_body} style={customStyles}>
			<GlobalAudio />
			<div style={{ zIndex: '100', position: 'relative' }}>
				{page == curPage && point == 'video' ? (
					<VideoOverlay
						noBg={true}
						disableDetails={true}
						video={data.map_video?.data?.attributes}
						onClose={closeVideo}
						add={addToPlaylist}
						loading={loading}
						added={added() != null}
					/>
				) : (
					<></>
				)}
			</div>
			{/* grains image not comming from backend */}
			<img
				className={Styles.main_body_background}
				src={(process.env.REACT_APP_BASE_URL ?? '') + data?.grains_image?.data?.attributes?.url}
				style={{ opacity: data?.grains_image?.data?.attributes?.url ? 1 : 0 }}
				alt={data?.grains_image?.data?.attributes?.alternativeText}
			/>
			<div className={Styles.custom_container}>
				<div className={Styles.content_container}>
					<div className={Styles.content_container_1}>
						<img
							src={
								(process.env.REACT_APP_BASE_URL ?? '') +
								data?.featured_image?.data?.attributes?.url
							}
							className={Styles.cc_1_img1 + ' ' + (page == curPage ? Styles.active : '')}
							alt={data?.featured_image?.data?.attributes?.alternativeText}
						/>
						<img
							src={
								(process.env.REACT_APP_BASE_URL ?? '') +
								data?.mark_image?.data?.attributes?.url
							}
							style={{ opacity: data?.mark_image?.data?.attributes?.url ? 1 : 0 }}
							className={Styles.cc_1_img2}
							alt={data?.mark_image?.data?.attributes?.alternativeText}
						/>
					</div>

					<div className={Styles.content_container_2}>
						<div className={Styles.episode_content}>
							<div className={Styles.episode_title}>
								<p className={Styles.episode_no}>{data.episode_number}</p>
								{page == curPage && point == 'story' ? (
									<div className={Styles.episode_story}>{data.story}</div>
								) : (
									<>
										<p
											className={
												Styles.episode_title_text +
												' ' +
												(page == curPage ? Styles.active : '')
											}
										>
											{data.title}
										</p>
										<p className={Styles.episode_author}>
											<span>Prepared by</span> {data.prepared_by}
										</p>
									</>
								)}
							</div>
						</div>
						<div className={Styles.episode_right_panel}>
							<img
								src={
									(process.env.REACT_APP_BASE_URL ?? '') +
									data?.random_image?.data?.attributes?.url
								}
								className={Styles.episode_right_panel_img}
								style={{ opacity: data?.random_image?.data?.attributes?.url ? 1 : 0 }}
								alt={data?.random_image?.data?.attributes?.alternativeText}
							/>
							<div className={Styles.episode_play_btns}>
								{data.type !== 'Khurseed' ? (
									<div onClick={openVideo} className={Styles.episode_play_btn_1}>
										<span className={Styles.arrow}>&gt;</span>play
									</div>
								) : (
									<div
										onClick={openVideo}
										className={Styles.episode_play_btn_1 + ' ' + Styles.khurseed_btn}
									>
										<img
											className={Styles.play_button_animation}
											src="./images/playbtn2.png"
										></img>
									</div>
								)}
								{data.type !== 'Khurseed' ? (
									<div
										className={Styles.episode_play_btn_2}
										style={point === 'story' ? { color: 'var(--themeColor)' } : {}}
										onClick={setShowStory}
									>
										<span className={Styles.arrow}>&gt;</span>
										{point == 'story' ? 'Back' : 'Story'}
									</div>
								) : (
									<></>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className={Styles.menu_bar + ' ' + (page == curPage ? Styles.menu_bar_show : '')}>
					{data.type !== 'Khurseed' ? (
						<img
							onClick={() =>
								document
									.getElementById('homepage')
									?.scrollIntoView({ behavior: 'smooth' })
							}
							src={
								(process.env.REACT_APP_BASE_URL ?? '') +
								config?.episodes_logo.data.attributes?.url
							}
							alt={config?.episodes_logo.data.attributes?.alternativeText}
							className={Styles.logo}
						/>
					) : (
						<img
							src="./images/logo_pink.png"
							onClick={() => {
								document
									.getElementById('homepage')
									?.scrollIntoView({ behavior: 'smooth' });
							}}
							alt={config?.episodes_logo.data.attributes?.alternativeText}
							className={Styles.logo}
						/>
					)}
					<div className={Styles.menu_items}>
						<MenuItems />
					</div>
				</div>
				<div
					className={
						Styles.vertical_line + ' ' + (page == curPage ? Styles.vertical_line_show : '')
					}
				></div>
				<div className={Styles.social_media_icons}>
					{data.type === 'Khurseed' ? (
						<ShareIcons
							text={`I discovered ${data.episode_number}, entitled, '${data.title}' from The Mapmaker from Baghdad here: ${window.location.href}`}
						/>
					) : (
						<ShareIcons
							text={`On a fateful night in 1979 Bombay, Rafique Baghdadi imprinted the map of his escape route onto the city. The chronicles of his adventure now survive with us. I discovered a part of Rafique's journey when I saw, ${data.title} from ‘The Mapmaker from Baghdad’ here - ${window.location.href}`}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default Episode;
