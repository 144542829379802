import MenuItems from '../../helper/MenuItems';
import styles from './fiction.module.scss';
import { WebsiteConfigsData } from '../../redux/constants/dashboard_objects';

const Fiction = ({ id, data }: { data?: WebsiteConfigsData | undefined; id: string }) => {
	return (
		<div id={id} className={styles.fiction}>
			<img src="./images/fictionbg.gif"></img>
			<div className={styles.front}>
				<div className={styles.menu_bar}>
					<div className={styles.menu_items}>
						<MenuItems />
					</div>
				</div>
				<div className={styles.vertical_line}></div>
				<div
					className={styles.content}
					dangerouslySetInnerHTML={{ __html: data?.fiction_text ?? '' }}
				></div>
			</div>
		</div>
	);
};

export default Fiction;
