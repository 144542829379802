import { useSearchParams } from 'react-router-dom';
import { MapData } from '../../redux/constants/dashboard_objects';
import Style from '../comman_styles/points.module.scss';
import PointsClicked from '../tooltip/PointsClicked';
import CustomToolTip from '../tooltip/ToolTip';
import { useSelector } from 'react-redux';
import { urlSearchSelector } from '../../redux/reducer/url_search.reducer';
import { useAppDispatch } from '../../store';
import { effectAction } from '../../redux/reducer/effect.reducer';
const Points = ({ data }: { data: { attributes: MapData; id: number }[] }) => {
	const curPage = 'page_33';
	const { page, point } = useSelector(urlSearchSelector);
	const [, setSearchParams] = useSearchParams();
	const selected = page == curPage ? point : null;
	const dispatch = useAppDispatch();

	const onClick = (index: number) => {
		dispatch(effectAction.onPointOpenEffect());
		setSearchParams({ page: curPage, point: index.toString() });
	};

	const onClose = () => {
		dispatch(effectAction.onPointCloseEffect());
		setSearchParams({ page: curPage });
	};

	return (
		<div style={{ position: 'relative' }}>
			<svg
				className={Style.map_svg}
				width="1280"
				height="800"
				viewBox="0 0 1280 800"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<CustomToolTip title={data[1]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(1)}
						d="M882.5 75L934 33.5L968 44.5L985 91L952.5 107.5L915 113L882.5 75Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[2]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(2)}
						d="M657.5 778V741.5L691 721.5L739 736.5V756L719.5 778H657.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[3]?.attributes.map_hover_text} placement="left">
					<path
						className={Style.select}
						onClick={() => onClick(3)}
						d="M1190 530.5L1229.5 511L1252 530.5V542L1262 574L1225.5 607L1182.5 566L1190 530.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[4]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(4)}
						d="M294.5 524L334 488H370L393.5 535L366.5 556L356.5 551L325.5 562.5L294.5 524Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[5]?.attributes.map_hover_text} placement="right">
					<path
						className={Style.select}
						onClick={() => onClick(5)}
						d="M92.5 192L153 126.5L225 157L216.5 207L171 240L92.5 192Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[6]?.attributes.map_hover_text} placement="right">
					<path
						className={Style.select}
						onClick={() => onClick(6)}
						d="M26.5 607L1.5 615V688.5L46.5 684.5L83.5 653V623.5L26.5 607Z"
						stroke="black"
					/>
				</CustomToolTip>
			</svg>
			{selected != null && (
				<PointsClicked
					data={data[selected].attributes}
					id={'map6-' + selected}
					onClose={onClose}
				/>
			)}
		</div>
	);
};

export default Points;
