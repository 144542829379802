import React, { useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react';
import styles from './Video_player.module.scss';
import ShareIcons from '../../helper/Share';
import { MapVideo } from '../../redux/constants/dashboard_objects';
import { audioAction, audioSelector } from '../../redux/reducer/audio.reducer';
import { useAppDispatch } from '../../store';
import pauseSVG from './pause.svg';
import pplaySVG from './play-alt-2.svg';
import bufferLoader from './loadingBar.gif';
import { useSelector } from 'react-redux';

const VideoOverlay = ({
    noBg,
    video,
    onClose,
    add,
    loading,
    added,
    disableDetails = false
}: {
    noBg?: boolean;
    disableDetails?: boolean;
    video: MapVideo;
    onClose: () => void;
    add: () => void;
    loading: boolean;
    added: boolean;
}) => {
    const [showVideo, setShowVideo] = useState(false);
    const [mouseMove, setMouseMove] = useState(true);
    const [showOverlay, setShowOverlay] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const dispatch = useAppDispatch();
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isWaiting, setIsWaiting] = useState(false);

    // Handle video time updates
    const handleTimeUpdate = () => {
        if (videoRef.current) {
            const current = videoRef.current.currentTime;
            setCurrentTime(current);
        }
    };

    // Handle video duration once metadata is loaded
    const handleLoadedMetadata = () => {
        if (videoRef.current) {
            const videoDuration = videoRef.current.duration;
            setDuration(videoDuration);
        }
    };

    // Handle manual time change (progress bar change)
    const handleProgressBarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const seekPercentage = parseFloat(e.target.value);
        const newTime = (seekPercentage / 100) * duration;
        if (videoRef.current) {
            videoRef.current.currentTime = newTime;
            setCurrentTime(newTime);
        }
    };

    // Format time in minutes:seconds
    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60) || 0;
        const seconds = Math.floor(time % 60) || 0;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    // Toggle play/pause
    const handlePlayPause = (startGlobalAudio = true) => {
        if (isPlaying) {
            _pause_video();
        } else {
            _play_video(startGlobalAudio);
        }
    };

    function _pause_video() {
        if (videoRef?.current == null) return;
        setIsPlaying(false);
        setShowOverlay(true);
        setIsWaiting(false);
        videoRef.current.pause();
    }

    function _play_video(startGlobalAudio = true) {
        if (videoRef?.current == null) return;
        if (startGlobalAudio) {
            dispatch(audioAction.autoPause());
        }
        setIsPlaying(true);
        setShowOverlay(false);
        videoRef.current.play();
    }

    // Automatically show video if noBg is true
    useEffect(() => {
        if (noBg) {
            setShowVideo(true);
        }
    }, [noBg]);

    const { isFirstInteraction } = useSelector(audioSelector);
    useLayoutEffect(() => {
        if (showVideo && !isPlaying && isFirstInteraction) {
            _play_video();
        }
    }, [showVideo, isFirstInteraction]);


    const [timeout, setTimeoutState] = useState<NodeJS.Timeout | null>(null);
    // Hide controls after inactivity
    const onMouseMove = () => {
        if (timeout) {
            clearTimeout(timeout);
        }
        setTimeoutState(setTimeout(function () {
            setMouseMove(false);
        }, 3500)); // 3.5 seconds
        setMouseMove(true);
    };

    // Generate share URL based on video type
    const shareUrl = useMemo(() => {
        switch (video.video_type) {
            case 'KHURSHEED':
                return `I discovered Letters From Khursheed, Number Four, entitled, ${video.video_title} from The Mapmaker from Baghdad here: ${window.location.href}`;
            case 'INTERVIEW':
                return `Oral testimonies abound within a city kept alive by its witnesses. ‘An ${video.video_title}’ from ‘The Mapmaker from Baghdad’ is here: ${window.location.href}`;
            case 'INTERLUDE':
                return `An interlude, a moment of pause, a site of contemplation from The Mapmaker from Baghdad here: ${window.location.href} `;
            case 'EPISODE':
                return `On a fateful night in 1979 Bombay, Rafique Baghdadi imprinted the map of his escape route onto the city. The chronicles of his adventure now survive with us. I discovered a part of Rafique's journey when I saw, ${video.video_title} from ‘The Mapmaker from Baghdad’ here: ${window.location.href}`;
            case 'PROLOGUE':
                return `It is here that our account of Rafique’s dreaded night begins; the prologue to a haunting, ‘The Thousand Clones of S Bhise’, from ‘The Mapmaker from Baghdad’, is available here: ${window.location.href}`;
            case 'EPILOGUE':
                return `…in the womb of this metabolic crowd, Rafique begun to grow a new body, to discover a new voice. His decade had reached its epilogue. ‘The Sky is their House’ from ‘The Mapmaker from Baghdad’, is available here: ${window.location.href}`;
            default:
                return '';
        }
    }, [video.video_type, video.video_title]);

    return (
        <div className={styles.video_overlay}>
            {/* Overlay Content */}
            {noBg ? (
                showOverlay ? (
                    <div
                        className={`${styles.video_overlay_container} ${styles.nobg}`}
                        style={{ zIndex: 30, background: '' }}
                    >
                        <div className={styles.video_top_nav_bar}>
                            <div className={styles.video_interaction_btns}>
                                <div
                                    className={styles.video_play_btn}
                                    onClick={() => {
                                        handlePlayPause();
                                    }}
                                >
                                    <img src="/images/playbtn2.png" alt="" />
                                    <span>PLAY</span>
                                </div>
                                <div className={styles.video_play_btn} onClick={() => add()}>
                                    {loading ? (
                                        <img
                                            style={{ height: '20px', width: '32px' }}
                                            className={styles.loadingBar}
                                            src="./images/loadingBar.gif"
                                            alt="Remove from Playlist"
                                        />
                                    ) : added ? (
                                        <img
                                            style={{ transform: 'rotate(180deg)' }}
                                            src="./images/playlist.png"
                                            alt="Remove from playlist"
                                        />
                                    ) : (
                                        <img src="./images/playlist.png" alt="" />
                                    )}
                                    <span>
                                        {loading
                                            ? 'LOADING'
                                            : added
                                                ? 'REMOVE FROM PLAYLIST'
                                                : 'ADD TO PLAYLIST'}
                                    </span>
                                </div>
                            </div>
                            <div
                                className={styles.video_close_btn}
                                onClick={() => {
                                    onClose();
                                    dispatch(audioAction.autoPlay());
                                }}
                            >
                                X
                            </div>
                        </div>
                        {disableDetails ? null : (
                            <div className={styles.video_information}>
                                <div className={styles.video_caption}>{video?.video_caption}</div>
                                <div className={styles.video_title}>{video?.video_title}</div>
                                <div className={styles.video_prepared_by}>
                                    <span>Prepared By</span> {video?.video_author}
                                </div>
                            </div>
                        )}
                        <div className={styles.video_social_media_icons}>
                            <ShareIcons text={shareUrl} />
                        </div>
                    </div>
                ) : null
            ) : (
                <div
                    className={styles.video_overlay_container}
                    style={{
                        background: `url(${(process.env.REACT_APP_BASE_URL ?? '') + video?.video_thumbnail?.data?.attributes?.url})`
                    }}
                >
                    <div className={styles.video_top_nav_bar}>
                        <div className={styles.video_interaction_btns}>
                            <div className={styles.video_play_btn} onClick={() => setShowVideo(true)}>
                                <img src="/images/playbtn2.png" alt="" />
                                <span>PLAY</span>
                            </div>
                            <div className={styles.video_play_btn} onClick={() => add()}>
                                {loading ? (
                                    <img
                                        style={{ height: '20px', width: '32px' }}
                                        className={styles.loadingBar}
                                        src="./images/loadingBar.gif"
                                        alt="Remove from Playlist"
                                    />
                                ) : added ? (
                                    <img
                                        style={{ transform: 'rotate(180deg)' }}
                                        src="./images/playlist.png"
                                        alt="Remove from playlist"
                                    />
                                ) : (
                                    <img src="./images/playlist.png" alt="" />
                                )}
                                <span>
                                    {loading
                                        ? 'LOADING'
                                        : added
                                            ? 'REMOVE FROM PLAYLIST'
                                            : 'ADD TO PLAYLIST'}
                                </span>
                            </div>
                        </div>
                        <div className={styles.video_close_btn} onClick={onClose}>
                            X
                        </div>
                    </div>
                    {disableDetails ? null : (
                        <div className={styles.video_information}>
                            <div className={styles.video_caption}>{video?.video_caption}</div>
                            <div className={styles.video_title}>{video?.video_title}</div>
                            <div className={styles.video_prepared_by}>
                                <span>Prepared By</span> {video?.video_author}
                            </div>
                        </div>
                    )}
                    <div className={styles.video_social_media_icons}>
                        <ShareIcons text={shareUrl} />
                    </div>
                </div>
            )}

            {/* Video Player */}
            {showVideo && (
                <div className={styles.video_player} onMouseMove={onMouseMove}>
                   {isWaiting && <div className={styles.video_buffer}><img src={bufferLoader} alt="Buffer loader" /></div>}
                    {!showOverlay && (
                        <div
                            className={styles.video_close_btn}
                            style={{ opacity: mouseMove ? '1' : '0' }}
                            onClick={() => {
                                onClose();
                                dispatch(audioAction.autoPlay());
                            }}
                        >
                            X
                        </div>
                    )}
                    <video
                        onClick={() => handlePlayPause()}
                        ref={videoRef}
                        onContextMenu={(e) => e.preventDefault()}
                        onLoadedMetadata={handleLoadedMetadata}
                        onTimeUpdate={handleTimeUpdate}
                        controls={false}
                        controlsList="nodownload nofullscreen noremoteplayback"
                        disablePictureInPicture
                        onWaiting={() => setIsWaiting(true)}
                        onPlaying={() => setIsWaiting(false)}
                        src={update_video_url(video.video_url)}

                    ></video>
                    {/* todo change hide unhide */}
                    <div className={styles.controls} style={{ opacity: mouseMove || showOverlay ? '1' : '0' }}>
                        {/* Play/Pause button */}
                        <img src={showOverlay ? pplaySVG : pauseSVG} onClick={() => handlePlayPause(false)}>
                        </img>

                        {/* Progress bar */}
                        <input
                            type="range"
                            min="0"
                            max="100"
                            step="0.1"
                            value={duration > 0 ? (currentTime / duration) * 100 : 0}
                            style={{
                                background: `linear-gradient(to right, #ccc ${duration > 0 ? (currentTime / duration) * 100 : 0}%, #b6b1b159 ${duration > 0 ? (currentTime / duration) * 100 : 0}%)`
                            }}
                            onChange={handleProgressBarChange}
                        />

                        {/* Time display */}
                        <div className={styles.time}>
                            {formatTime(currentTime)} / {formatTime(duration)}
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.animation}></div>
        </div>
    );
};

const update_video_url = (url: string): string => {
    return url.replace(
        'https://cdn.themapmakerfrombaghdad.com/view/data/state/',
        'https://video.themapmakerfrombaghdad.com/'
    );
};

export default VideoOverlay;
