// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserButtons_buttons__WRx1b {
  display: flex;
  justify-content: space-between;
}
.UserButtons_buttons__WRx1b .UserButtons_leftButton__DYfR3,
.UserButtons_buttons__WRx1b .UserButtons_rightButton__BS7bC {
  text-transform: uppercase;
  cursor: pointer;
  width: max-content;
  font-size: 10px;
  letter-spacing: -0.4px;
  font-family: FSB;
  padding: 7.2px 14px;
  border: 1px solid;
  border-radius: 50px;
  transition: 0.5s;
}
.UserButtons_buttons__WRx1b .UserButtons_leftButton__DYfR3:hover,
.UserButtons_buttons__WRx1b .UserButtons_rightButton__BS7bC:hover {
  box-shadow: inset 200px 0 0 0 white;
}
.UserButtons_buttons__WRx1b .UserButtons_leftButton__DYfR3 {
  background: white;
  color: black;
}
.UserButtons_buttons__WRx1b .UserButtons_leftButton__DYfR3:hover {
  color: black;
}
.UserButtons_buttons__WRx1b .UserButtons_rightButton__BS7bC {
  background: rgba(0, 0, 0, 0.004);
  color: white;
}
.UserButtons_buttons__WRx1b .UserButtons_rightButton__BS7bC:hover {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/componenets/user_page/common/user_buttons/UserButtons.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;AACJ;AACI;;EAEI,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AACR;AACQ;;EACI,mCAAA;AAEZ;AAEI;EACI,iBAAA;EACA,YAAA;AAAR;AAEQ;EACI,YAAA;AAAZ;AAII;EACI,gCAAA;EACA,YAAA;AAFR;AAIQ;EACI,YAAA;AAFZ","sourcesContent":[".buttons {\n    display: flex;\n    justify-content: space-between;\n\n    .leftButton,\n    .rightButton {\n        text-transform: uppercase;\n        cursor: pointer;\n        width: max-content;\n        font-size: 10px;\n        letter-spacing: -0.4px;\n        font-family: FSB;\n        padding: 7.2px 14px;\n        border: 1px solid;\n        border-radius: 50px;\n        transition: 0.5s;\n\n        &:hover {\n            box-shadow: inset 200px 0 0 0 white;\n        }\n    }\n\n    .leftButton {\n        background: white;\n        color: black;\n\n        &:hover {\n            color: black;\n        }\n    }\n\n    .rightButton {\n        background: rgba(0, 0, 0, 0.004);\n        color: white;\n\n        &:hover {\n            color: black;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": `UserButtons_buttons__WRx1b`,
	"leftButton": `UserButtons_leftButton__DYfR3`,
	"rightButton": `UserButtons_rightButton__BS7bC`
};
export default ___CSS_LOADER_EXPORT___;
