import { MenuItemsTypes } from '../../helper/MenuItems';
import Style from '../comman_styles/points.module.scss';
const Points = () => {
	const onClick = (id: string) => {
		// smoothscrol
		document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<svg
			width="1280"
			height="800"
			viewBox="0 0 1280 800"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				id="point1"
				className={Style.select}
				onClick={() => onClick(MenuItemsTypes.ABOUT)}
				d="M266.971 244.424L237.531 310.42L328.777 328.154L400.457 270.384L266.971 244.424Z"
				stroke="black"
			/>
			<path
				id="point2"
				className={Style.select}
				onClick={() => onClick(MenuItemsTypes.USER)}
				d="M820.297 126.691L817.006 42.9616L903.497 66.362L914.103 130.713L892.343 159.049L820.297 126.691Z"
				stroke="black"
			/>
			<path
				id="point3"
				className={Style.select}
				onClick={() => onClick(MenuItemsTypes.FICTION)}
				d="M495.36 737.843L442.332 702.742L514.194 676.051L587.886 698.355L569.052 737.843H495.36Z"
				stroke="black"
			/>
			<path
				id="point4"
				className={Style.select}
				onClick={() => onClick(MenuItemsTypes.ARCHIVE)}
				d="M1136.09 538.94L1072.82 547.532L1057.83 584.461L1072.82 634.918L1136.09 619.196V538.94Z"
				stroke="black"
			/>
		</svg>
	);
};

export default Points;
