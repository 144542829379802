import axios from 'axios';
class HTTPClient {
	baseUrl: string = '';
	constructor() {
		if (process.env.REACT_APP_BASE_URL) {
			this.baseUrl = process.env.REACT_APP_BASE_URL;
		} else {
			console.log('REACT_APP_BASE_URL not defined in env file');
		}
	}

	get(url: string, customHeaders: any) {
		const headers = {
			...customHeaders,
			'Content-Type': 'application/json'
		};
		return axios.get(url, { headers });
	}

	post(url: string, data: any, customHeaders: any = {}) {
		const headers = {
			...customHeaders,
			'Content-Type': 'application/json'
		};
		return axios.post(url, data, { headers });
	}

	delete(url: string, customHeaders: any = {}) {
		const headers = {
			...customHeaders,
			'Content-Type': 'application/json'
		};
		return axios.delete(url, { headers });
	}
}

export const CustomHTTPClient = new HTTPClient();
