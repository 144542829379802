import { FormEvent, useState } from "react";
import { EmailInput, UserMessage, PasswordInput, UserError, UserInput } from "./common/user_input/UserInput";
import UserButtons from "./common/user_buttons/UserButtons";
import { ButtonConstants, ErrorMessage } from "./common/Constants";
import { authAction, AuthPage, signUpUser } from "../../redux/reducer/auth.reducer";
import { useAppDispatch } from "../../store";
import { validatePassword } from "./Validation";

const SignUpComponent = ({ email, setEmail }: { email: string, setEmail: (value: string) => void }) => {

    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useAppDispatch();

    function resetError() {
        setEmailError(false);
        setPasswordError(false);
        setError(null);
        setUsernameError(false);
        setMessage(null);
    }

    async function onSubmit(e: FormEvent) {
        e.preventDefault();
        if (loading) return;
        resetError();

        if (!validatePassword(password)) {
            setPasswordError(true);
            setError('Password must be at least 8 characters');
            return;
        }

        if (username.length < 4) {
            setUsernameError(true);
            setError('Username must be at least 4 characters');
            return;
        }
        const [error, user] = await signUpUser(email, password, username);
        if (error || user == null) {
            if (error === ErrorMessage.EMAIL_NOT_CONFIRMED) {
                dispatch(authAction.update_state(AuthPage.EMAIL_NOT_CONFIRMED));
            } else {
                if(error === ErrorMessage.EMAIL_ALREADY_EXIST){
                    setEmailError(true);
                }
                setError(error ?? "Something went wrong. Please try again later.");
            }
        } else {
            dispatch(authAction.logged_in(user));
        }

        setPassword('');
        setLoading(false);
    }

    function onGoToLoginIn() {
        if (loading) return;
        dispatch(authAction.update_state(AuthPage.LOGIN));
    }

    return (
        <form onSubmit={onSubmit}>
            <UserInput inputValue={username} setInputValue={setUsername} error={usernameError} />
            <EmailInput inputValue={email} setInputValue={setEmail} error={emailError} />
            <PasswordInput title="password" inputValue={password} setInputValue={setPassword} error={passwordError} />
            <UserMessage message={message} />
            <UserError error={error} />
            <UserButtons
                leftButtonData={{ text: ButtonConstants.GO_TO_LOGIN, onClick: onGoToLoginIn }}
                rightButtonData={{ text: ButtonConstants.ENTER_ARCHIVE, onClick: () => { }, type: 'submit' }} />
        </form>
    )
}

export default SignUpComponent;