import { CycleCinemasData } from '../../redux/constants/dashboard_objects';
import { properties } from '../../redux/constants/main_objects';
import GlobalAudio from '../global_audio/GlobalAudio';
import style from './cycle_cinema.module.scss';

const CycleCinemas = ({ data }: { data: CycleCinemasData | undefined }) => {
	if (data == undefined) return <div>cycle_cinemas</div>;

	return (
		<div className={style.cycle_cinema}>
			{' '}
			<GlobalAudio />
			<img
				style={{
					width: properties.width,
					height: properties.height
				}}
				src={(process.env.REACT_APP_BASE_URL ?? '') + data.cycle_cinema_gif.data.attributes.url}
				alt={data.cycle_cinema_gif.data.attributes.alternativeText}
			/>
		</div>
	);
};

export default CycleCinemas;
