import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip
		{...props}
		classes={{
			popper: className
		}}
		arrow
	/>
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: '175px',
		padding: '10px 15px',
		backgroundColor: 'white',
		color: 'black',
		borderRadius: '20px',
		fontFamily: 'ESR',
		fontSize: '13px',
		lineHeight: '1.1',
		textAlign: 'left',
		boxShadow: '8px 8px 8px rgba(0,0,0,0.35)'
	}
});

export default CustomToolTip;
