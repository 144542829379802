// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./cursor.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cycle_cinema_cycle_cinema__vCJkp {
  cursor: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), auto;
}`, "",{"version":3,"sources":["webpack://./src/componenets/cycle_cinemas/cycle_cinema.module.scss"],"names":[],"mappings":"AAAA;EACC,qDAAA;AACD","sourcesContent":[".cycle_cinema {\n\tcursor: url('./cursor.svg'), auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cycle_cinema": `cycle_cinema_cycle_cinema__vCJkp`
};
export default ___CSS_LOADER_EXPORT___;
