import { toast } from 'react-toastify';

export const toastMiddleware = (_: any) => {
	return function (next: any) {
		return function (action: any) {
			if (action?.type?.includes('auth/')) {
				next(action);
				return;
			}
			switch (action.type) {
				// case 'auth/loginUser/fulfilled':
				// 	toast.info('Logged in successfully');
				// 	break;
				// case 'auth/signUpUser/fulfilled':
				// 	toast.info('SignUp successfully');
				// 	break;
				// case 'auth/logoutUser/fulfilled':
				// 	toast.info('Logged out successfully');
				// 	break;
				// case 'auth/verifyToken/rejected':
				// 	break;
				case 'helper/customToast':
					toast.info(action.payload);
					break;

				default:
					if (action.error) {
						console.log('error', action.error);
					}
					if (action.error?.message) {
						toast.error(action.error.message);
					}
			}
			next(action);
		};
	};
};
