const mainData = {
	twitter: 'https://twitter.com',
	facebook: 'https://facebook.com',
	instagram: 'https://instagram.com',
	logo: 'https://picsum.photos/50/100'
};

export const properties = {
	width: '1280px',
	height: '800px'
};
export default mainData;
