import style from './GlobalAudio.module.scss';
import { useSelector } from 'react-redux';
import { audioAction, audioSelector } from '../../redux/reducer/audio.reducer';
import { useAppDispatch } from '../../store';

function GlobalAudio() {
	const dispatch = useAppDispatch();
	const { isPausedByUser, isAutoPaused, isFirstInteraction } = useSelector(audioSelector);

	const playAudio = () => {
		dispatch(audioAction.playByUser());
	};

	const stopAudio = () => {
		dispatch(audioAction.pauseByUser());
	};

	const updateState = () => {
		if (isAutoPaused || isPausedByUser || isFirstInteraction == false) {
			playAudio();
		} else {
			stopAudio();
		}
	};

	return (
		<div>
			<div className={style.globalAudioBtn} onClick={updateState}>
				{isAutoPaused || isPausedByUser || isFirstInteraction == false ? (
					<img src="./mute_icon.png" className={style.globalMute} />
				) : (
					<img src="./audio.webp" className={style.globalUnmute} />
				)}
			</div>
		</div>
	);
}

export default GlobalAudio;
