import { MapData } from '../../redux/constants/dashboard_objects';
import Style from '../comman_styles/points.module.scss';
import PointsClicked from '../tooltip/PointsClicked';
import { useSearchParams } from 'react-router-dom';
import CustomToolTip from '../tooltip/ToolTip';
import { useSelector } from 'react-redux';
import { urlSearchSelector } from '../../redux/reducer/url_search.reducer';
import { useAppDispatch } from '../../store';
import { effectAction } from '../../redux/reducer/effect.reducer';

const Points = ({ data }: { data: { attributes: MapData; id: number }[] }) => {
	const curPage = 'page_5';
	const { page, point } = useSelector(urlSearchSelector);
	const [, setSearchParams] = useSearchParams();
	const selected = page == curPage ? point : null;
	const dispatch = useAppDispatch();

	const onClick = (index: number) => {
		dispatch(effectAction.onPointOpenEffect());
		setSearchParams({ page: curPage, point: index.toString() });
	};

	const onClose = () => {
		dispatch(effectAction.onPointCloseEffect());
		setSearchParams({ page: curPage });
	};

	return (
		<div style={{ position: 'relative' }}>
			<svg
				className={Style.map_svg}
				width="1280"
				height="800"
				viewBox="0 0 1280 800"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<CustomToolTip title={data[0]?.attributes.map_hover_text} placement="right">
					<path
						data-tooltip-content={data[0]?.attributes.map_hover_text}
						id="point1"
						className={Style.select}
						onClick={() => onClick(0)}
						d="M44 351.5L26 356L40 381L59 373.5L44 351.5Z"
						stroke="black"
					/>
				</CustomToolTip>

				<CustomToolTip title={data[1]?.attributes.map_hover_text}>
					<path
						data-tooltip-content={data[1]?.attributes.map_hover_text}
						id="point2"
						className={Style.select}
						onClick={() => onClick(1)}
						d="M411 522L389.5 538.5L405 558L407 548L417.5 538.5L411 522Z"
						stroke="black"
					/>
				</CustomToolTip>

				<CustomToolTip title={data[2]?.attributes.map_hover_text}>
					<path
						data-tooltip-content={data[2]?.attributes.map_hover_text}
						id="point3"
						className={Style.select}
						onClick={() => onClick(2)}
						d="M106 670L112.5 638.5L126 650.5L119.5 664V676.5L126 690.5V695.5L106 670Z"
						stroke="black"
					/>
				</CustomToolTip>

				<CustomToolTip title={data[3]?.attributes.map_hover_text}>
					<path
						data-tooltip-content={data[3]?.attributes.map_hover_text}
						id="point4"
						className={Style.select}
						onClick={() => onClick(3)}
						d="M762 629V614.5L780 623.5L777 629L774 637L762 629Z"
						stroke="black"
					/>
				</CustomToolTip>

				<CustomToolTip title={data[4]?.attributes.map_hover_text}>
					<path
						data-tooltip-content={data[4]?.attributes.map_hover_text}
						id="point5"
						className={Style.select}
						onClick={() => onClick(4)}
						d="M1125.5 658V641.5H1129L1138 633.5L1145.5 639.5V646L1143.5 652.5H1140L1135 658L1131 654.5L1125.5 658Z"
						stroke="black"
					/>
				</CustomToolTip>

				<CustomToolTip title={data[5]?.attributes.map_hover_text}>
					<path
						data-tooltip-content={data[5]?.attributes.map_hover_text}
						id="point6"
						className={Style.select}
						onClick={() => onClick(5)}
						d="M774.5 219.5H754L761.5 241L779.5 228.5L774.5 219.5Z"
						stroke="black"
					/>
				</CustomToolTip>

				<CustomToolTip title={data[6]?.attributes.map_hover_text} placement="left">
					<path
						data-tooltip-content={data[6]?.attributes.map_hover_text}
						id="point7"
						className={Style.select}
						onClick={() => onClick(6)}
						d="M1238.5 185.5L1239 176.5H1242L1247.5 179.5L1249.5 178.5L1250 175H1255.5V195.5H1242V192H1234V188.5L1238.5 185.5Z"
						stroke="black"
					/>
				</CustomToolTip>
			</svg>
			{selected != null && (
				<PointsClicked
					data={data[selected].attributes}
					id={'map1-' + selected}
					onClose={onClose}
				/>
			)}
		</div>
	);
};

export default Points;
