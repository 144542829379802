import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../service/auth.service';
import { RootState } from '../../store';
import { EpiloguesData, EpisodesData, MapData, ProloguesData } from '../constants/dashboard_objects';
import { ErrorMessage } from '../../componenets/user_page/common/Constants';
import { PointsClickedData } from '../../componenets/tooltip/PointsClicked';

export type UserType = {
	id: number;
	username: string;
	email: string;
	fullname: string;
	provider: string;
	confirmed: boolean;
	blocked: boolean;
	createdAt: string;
	updatedAt: string;
	playlists: Playlist[];
} | null;

export type Playlist = {
	id: number;
	playlist: MapData | EpisodesData | EpiloguesData | ProloguesData;
	unique: string;
};

export enum AuthPage {
	WHY_SIGNUP = 'Why Sign Up',
	LOGIN = 'Login',
	SIGNUP = 'Sign Up',
	RESET_PASSWORD = 'Reset Password',
	FORGOT_PASSWORD = 'Forgot Password',
	EMAIL_NOT_CONFIRMED = 'Email Not Confirmed',
	PLAYLIST = 'Playlist',
}

type AuthStateType = {
	user: UserType;
	page: AuthPage;
	loading: boolean
};

type ReturnType = [
	error?: string | null,
	userData?: UserType | null
]
const initialState: AuthStateType = { user: null, page: AuthPage.WHY_SIGNUP, loading: false };
const USER_TOKEN_KEY = 'token';

export async function loginUser(identifier: string, password: string)
	: Promise<ReturnType> {
	try {
		let data = await AuthService.loginUser(identifier, password);
		if (data?.user?.confirmed === false) {
			return [ErrorMessage.EMAIL_NOT_CONFIRMED];
		}
		localStorage.setItem(USER_TOKEN_KEY, data?.jwt);
		data = await AuthService.verifyToken(data?.jwt);
		return [null, data];
	} catch (error: any) {
		console.log(error);
		
		if(error?.response?.data?.error?.message == 'Invalid identifier or password'){
			return [ErrorMessage.INCORRECT_EMAIL_OR_PASSWORD];
		}
		
		return [ErrorMessage.SOMETHING_WENT_WRONG];
	}
}

/**
 * Logout the user by signing them out
 */
export const logoutUser = createAsyncThunk('auth/logoutUser', async (_, thunkApi) => {
	thunkApi.dispatch(authAction.loading());
	const token = localStorage.getItem(USER_TOKEN_KEY);
	if (token) {
		localStorage.removeItem(USER_TOKEN_KEY);
	}
});

export async function signUpUser(email: string, password: string, fullname: string)
	: Promise<ReturnType> {
	try {
		const data = await AuthService.signUpUser(email, password, fullname);
		if (data?.user?.confirmed === false) {
			return [ErrorMessage.EMAIL_NOT_CONFIRMED];
		}
		localStorage.setItem(USER_TOKEN_KEY, data?.jwt);
		return [null, data?.user];
	} catch (error: any) {
		if (error?.response?.data?.error?.message == 'Email or Username are already taken') {
			return [ErrorMessage.EMAIL_ALREADY_EXIST];
		} else {
			return [ErrorMessage.SOMETHING_WENT_WRONG];
		}
	}
}

export async function forgotPasswordUser(email: string): Promise<[error?: string]> {
	try {
		await AuthService.forgotPassword(email);
		return [];
	} catch (error) {
		return [ErrorMessage.SOMETHING_WENT_WRONG];
	}
}

export async function resetPasswordUser(code: string, password: string): Promise<[error?: string]> {
	try {
		await AuthService.resetPassword(code, password, password);
		return [];
	} catch (error) {
		return [ErrorMessage.SOMETHING_WENT_WRONG];
	}
}

export const verifyToken = createAsyncThunk('auth/verifyToken', async (_, thunkApi) => {
	thunkApi.dispatch(authAction.loading());
	const token = localStorage.getItem('token');
	if (token === undefined || token === null) throw new Error('Token not found in cookie');
	const data = await AuthService.verifyToken(token);
	return data;
});

export const addToFavorites = createAsyncThunk(
	'auth/addToFavorites',
	async (arg: { user_id: number; playlist_id: string; playlist: PointsClickedData }, thunkApi) => {
		thunkApi.dispatch(authAction.loading());
		const token = localStorage.getItem(USER_TOKEN_KEY);
		if (token === undefined || token === null) throw new Error('Token not found in cookie');
		await AuthService.addToPlaylist(token, arg.user_id, arg.playlist_id, arg.playlist);
		const data = await AuthService.verifyToken(token);
		return data;
	}
);

export const deleteFavorites = createAsyncThunk(
	'auth/removeFromFavorites',
	async (arg: { id: number }, thunkApi) => {
		thunkApi.dispatch(authAction.loading());
		const token = localStorage.getItem(USER_TOKEN_KEY);
		if (token === undefined || token === null) throw new Error('Token not found in cookie');
		await AuthService.deleteFromPlaylist(token, arg.id);
		const data = await AuthService.verifyToken(token);
		return data;
	}
);

const authSlice = createSlice({
	name: 'auth',
	initialState: initialState,
	reducers: {
		update_state: (state, action) => {
			state.user = null;
			state.page = action.payload;
			state.loading = false;
		},

		logged_in: (state, action) => {
			state.loading = false;
			state.user = action.payload;
			state.page = AuthPage.PLAYLIST;
		},
		loading: (state) => {
			state.loading = true;
		}
	},
	extraReducers: (builder: ActionReducerMapBuilder<AuthStateType>): void => {
		builder
			.addCase(logoutUser.fulfilled, (state: AuthStateType) => {
				state.loading = false;
				state.user = null;
				state.page = AuthPage.WHY_SIGNUP;
			})
			.addCase(logoutUser.rejected, (state: AuthStateType) => {
				state.loading = false;
				state.user = null;
				state.page = AuthPage.WHY_SIGNUP;
			})
			.addCase(verifyToken.fulfilled, (state: AuthStateType, action: { payload: UserType }) => {
				state.loading = false;
				state.user = action?.payload;
				state.page = AuthPage.PLAYLIST;
			})
			.addCase(verifyToken.rejected, (state: AuthStateType) => {
				state.loading = false;
				state.user = null;
				state.page = AuthPage.WHY_SIGNUP;
			})
			.addCase(addToFavorites.fulfilled, (state: AuthStateType, action: { payload: UserType }) => {
				state.loading = false;
				state.user = action?.payload;
				state.page = AuthPage.PLAYLIST;
			})
			.addCase(addToFavorites.rejected, (state: AuthStateType) => {
				state.loading = false;
			})
			.addCase(deleteFavorites.fulfilled, (state: AuthStateType, action: { payload: UserType }) => {
				state.loading = false;
				state.user = action?.payload;
				state.page = AuthPage.PLAYLIST;
			})
			.addCase(deleteFavorites.rejected, (state: AuthStateType) => {
				state.loading = false;
			});
	}
});

export const authReducer = authSlice.reducer;
export const authAction = authSlice.actions;
export const authSelector = (state: RootState) => state.authReducer;
