import Points from './points';
import styles from './homescreen.module.scss';
import mapStyles from '../comman_styles/points.module.scss';
import { Image } from '../../redux/constants/dashboard_objects';

const Homescreen = ({ id, background }: { id: string; background: Image }) => {
	return (
		<div id={id} className={styles.relative}>
			<img
				className={styles.absolute}
				style={{ zIndex: -1 }}
				src={(process.env.REACT_APP_BASE_URL ?? '') + background?.data?.attributes?.url}
				alt={background?.data?.attributes?.alternativeText}
			></img>
			<img
				className={styles.absolute + ' ' + styles.contain + ' ' + mapStyles.glow}
				style={{ zIndex: 1 }}
				src="./images/home/Home.png"
			></img>
			<div className={styles.absolute} style={{ zIndex: 2 }}>
				<Points />
			</div>
		</div>
	);
};

export default Homescreen;
