import Styles from './UserInput.module.scss';
import LoadingBarGif from './loadingBar.gif';

export const UserInput = ({ inputValue, setInputValue, error }: { inputValue: string, setInputValue: (value: string) => void, error: boolean }) => {
    return (
        <div className={Styles.input_group}>
            <label htmlFor="username">username</label>
            <input
                name="username"
                type="text"
                autoComplete='username'
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={{ borderColor: error ? 'red' : '' }}
                required
            />
        </div>
    );
}

export const PasswordInput = ({ inputValue, setInputValue, error, title, onForgotPassword }:
    { inputValue: string, setInputValue: (value: string) => void, error: boolean, title: string, onForgotPassword?: () => void }) => {
    return (
        <div className={Styles.input_group}>
            <div className={Styles.heading}>
                <label htmlFor={title}>{title}</label>
                {
                    onForgotPassword && <div className={Styles.forgot_password} onClick={onForgotPassword}>forgot password?</div>
                }
            </div>
            <input
                name={title}
                type="password"
                autoComplete='current-password'
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={{ borderColor: error ? 'red' : '' }}
                required

            />
        </div>
    );
}

export const EmailInput = ({ inputValue, setInputValue, error }: { inputValue: string, setInputValue: (value: string) => void, error: boolean }) => {
    return (
        <div className={Styles.input_group}>
            <label htmlFor="email">email id</label>
            <input
                name="email"
                type="email"
                autoComplete='email'
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={{ borderColor: error ? 'red' : '' }}
                required
            />
        </div>
    );
}

export const UserError = ({ error }: { error: string | null }) => {
    return (
        error ? <div className={Styles.error}>{error}</div> : <></>
    );
}

export const UserMessage = ({ message }: { message: string | null }) => {
    return (
        message ? <div className={Styles.message}>{message}</div> : <></>
    );
}

export const UserLoading = ({ showLoading }: { showLoading?: boolean }) => {
    if (showLoading) {
        return <img style={{ width: "32px", height: "20px", marginTop: "14px" }} src={LoadingBarGif} />
    }
    return <></>;
}