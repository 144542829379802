import { FormEvent, useState } from "react";
import { EmailInput, UserMessage, PasswordInput, UserError } from "./common/user_input/UserInput";
import UserButtons from "./common/user_buttons/UserButtons";
import { ButtonConstants, ErrorMessage } from "./common/Constants";
import { authAction, AuthPage, loginUser } from "../../redux/reducer/auth.reducer";
import { useAppDispatch } from "../../store";
import { validatePassword } from "./Validation";

const LoginComponent = ({ email, setEmail }: { email: string, setEmail: (value: string) => void }) => {

    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [password, setPassword] = useState('');
    const dispatch = useAppDispatch();

    function resetError() {
        setEmailError(false);
        setPasswordError(false);
        setError(null);
        setMessage(null);
    }

    async function submit(e: FormEvent) {
        if (loading) return;
        e.preventDefault();
        resetError();

        if (validatePassword(password) === false) {
            setPasswordError(true);
            setError('Password must be at least 8 characters');
            return;
        }
        const [error, user] = await loginUser(email, password);
        if (error || user == null) {
            if (error === ErrorMessage.INCORRECT_EMAIL_OR_PASSWORD) {
                setEmailError(true);
                setPasswordError(true);
            }
            setError(error ?? "Something went wrong. Please try again later.");
        } else {
            dispatch(authAction.logged_in(user));
        }

        setPassword('');
        setLoading(false);
    }

    function onGoToSignUp() {
        if (loading) return;
        dispatch(authAction.update_state(AuthPage.SIGNUP));
    }

    function onForgotPassword() {
        if (loading) return;
        dispatch(authAction.update_state(AuthPage.FORGOT_PASSWORD));
    }

    return (
        <form onSubmit={submit}>
            <EmailInput inputValue={email} setInputValue={setEmail} error={emailError} />
            <PasswordInput title="password" inputValue={password} setInputValue={setPassword} error={passwordError} onForgotPassword={onForgotPassword} />
            <UserMessage message={message} />
            <UserError error={error} />
            <UserButtons
                leftButtonData={{ text: ButtonConstants.GO_TO_SIGNUP, onClick: onGoToSignUp }}
                rightButtonData={{ text: ButtonConstants.ENTER_ARCHIVE, onClick: () => { }, type: 'submit' }} />
        </form>
    )
}

export default LoginComponent;