// import { toast } from 'react-toastify';
// export const shareInstagram = (url: string, type: string, title: string) => {
// 	return `https://www.instagram.com/sharer/sharer.php?u=${url}`;
// };

// export const shareFacebook = (url: string, type: string, title: string) => {
// 	return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
// };

// export const shareTwitter = (url: string, contentType: string, title: string) => {
// 	const message =
// 		'You are sharing ' +
// 		contentType +
// 		' with title ' +
// 		title +
// 		' from The Map Maker from Baghdad click this link to check - ' +
// 		window.location.href;
// 	// window.location.href = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`;
// 	return `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`;
// };

// export const shareAudio = (url: string, title: string) => {
// 	toast.info('Link copied to your clipboard');
// 	const message =
// 		'You are sharing Audio with title ' +
// 		title +
// 		' from The Map Maker from Baghdad click this link to listen - ' +
// 		window.location.href +
// 		url;
// 	navigator.clipboard
// 		.writeText(message)
// 		.then(() => {})
// 		.catch((err) => {
// 			console.error('Failed to copy: ', err);
// 		});
// 	`https://www.facebook.com/sharer/sharer.php?u=${message}`;
// };

const ShareIcons = ({ text }: { text: string }) => {
	return (
		<>
			<a
				href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`}
				target="_blank"
				rel="noreferrer"
			>
				<img src="./images/twitter.png" alt="twitter logo" />
			</a>
			{/* <a
				href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(text)}`}
				target="_blank"
				rel="noreferrer"
			>
				<img src="./images/facebook.png" alt="facebook logo" />
			</a> */}
			<a
				href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent("https://test.themapmakerfrombaghdad.com")}`}
				target="_blank"
				rel="noreferrer"
			>
				<img src="./images/facebook.png" alt="facebook logo" />
			</a>

			<a href={`https://www.instagram.com/lightcube.in/`} target="_blank" rel="noreferrer">
				<img src="./images/instagram.png" alt="instagram" />
			</a>
		</>
	);
};

export default ShareIcons;
