// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuItems_menu_transition_animation__jtncM a {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: inline-block;
  color: white;
}
.MenuItems_menu_transition_animation__jtncM a::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(-200%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  color: white;
}
.MenuItems_menu_transition_animation__jtncM a:hover::after {
  transform: translate3d(0, 0, 0);
}
.MenuItems_menu_transition_animation__jtncM a span {
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}
.MenuItems_menu_transition_animation__jtncM a:hover span {
  transform: translate3d(200%, 0, 0);
}`, "",{"version":3,"sources":["webpack://./src/helper/MenuItems.module.scss"],"names":[],"mappings":"AACC;EACC,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,YAAA;AAAF;AAGC;EACC,WAAA;EACA,kBAAA;EACA,WAAA;EACA,OAAA;EACA,2BAAA;EACA,YAAA;EACA,MAAA;EACA,0BAAA;EACA,mCAAA;EACA,yDAAA;EACA,YAAA;AADF;AAIC;EACC,+BAAA;AAFF;AAKC;EACC,qBAAA;EACA,yDAAA;AAHF;AAMC;EACC,kCAAA;AAJF","sourcesContent":[".menu_transition_animation {\n\ta {\n\t\tcursor: pointer;\n\t\toverflow: hidden;\n\t\tposition: relative;\n\t\tdisplay: inline-block;\n\t\tcolor: white;\n\t}\n\n\ta::after {\n\t\tcontent: '';\n\t\tposition: absolute;\n\t\twidth: 100%;\n\t\tleft: 0;\n\t\tcontent: attr(data-replace);\n\t\theight: 100%;\n\t\ttop: 0;\n\t\ttransform-origin: 100% 50%;\n\t\ttransform: translate3d(-200%, 0, 0);\n\t\ttransition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);\n\t\tcolor: white;\n\t}\n\n\ta:hover::after {\n\t\ttransform: translate3d(0, 0, 0);\n\t}\n\n\ta span {\n\t\tdisplay: inline-block;\n\t\ttransition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);\n\t}\n\n\ta:hover span {\n\t\ttransform: translate3d(200%, 0, 0);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu_transition_animation": `MenuItems_menu_transition_animation__jtncM`
};
export default ___CSS_LOADER_EXPORT___;
