import { useSearchParams } from 'react-router-dom';
import Style from '../comman_styles/points.module.scss';
import CustomToolTip from '../tooltip/ToolTip';
import { MapData } from '../../redux/constants/dashboard_objects';
import PointsClicked from '../tooltip/PointsClicked';
import { useSelector } from 'react-redux';
import { urlSearchSelector } from '../../redux/reducer/url_search.reducer';
import { useAppDispatch } from '../../store';
import { effectAction } from '../../redux/reducer/effect.reducer';

const Points = ({ data }: { data: { attributes: MapData; id: number }[] }) => {
	const curPage = 'page_25';
	const { page, point } = useSelector(urlSearchSelector);
	const [, setSearchParams] = useSearchParams();
	const selected = page == curPage ? point : null;
	const dispatch = useAppDispatch();

	const onClick = (index: number) => {
		dispatch(effectAction.onPointOpenEffect());
		setSearchParams({ page: curPage, point: index.toString() });
	};

	const onClose = () => {
		dispatch(effectAction.onPointCloseEffect());
		setSearchParams({ page: curPage });
	};

	return (
		<div style={{ position: 'relative' }}>
			<svg
				className={Style.map_svg}
				width="1280"
				height="800"
				viewBox="0 0 1280 800"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<CustomToolTip title={data[0]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(0)}
						d="M1279 335.5V292.5H1241V335.5H1279Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[1]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(1)}
						d="M1100 103V68H1184.5V103H1100Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[2]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(2)}
						d="M842 214V146H904V214H842Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[3]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(3)}
						d="M711 542.5V416H778V542.5H711Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[4]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(4)}
						d="M345.5 143V43H431.5V143H345.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[5]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(5)}
						d="M499.5 768.5V714H543.5V768.5H499.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[6]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(6)}
						d="M982 668V563H1038.5V668H982Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[7]?.attributes.map_hover_text} placement="right">
					<path
						className={Style.select}
						onClick={() => onClick(7)}
						d="M192.5 443.5H1V398H192.5V443.5Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[8]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(8)}
						d="M412 363.5V317.5H461.5V363.5H412Z"
						stroke="black"
					/>
				</CustomToolTip>
				<CustomToolTip title={data[9]?.attributes.map_hover_text}>
					<path
						className={Style.select}
						onClick={() => onClick(9)}
						d="M289.5 566.5H317.5M317.5 566.5V693H227.5V566.5H317.5Z"
						stroke="black"
					/>
				</CustomToolTip>
			</svg>
			{selected != null && (
				<PointsClicked
					data={data[selected].attributes}
					id={'map4-' + selected}
					onClose={onClose}
				/>
			)}
		</div>
	);
};

export default Points;
